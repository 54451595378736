<div class="chex-bottom_space">
    <ruf-page-header title="ID Theft Block" description=" "></ruf-page-header>
    <div role="region"  class="chex-page-left-right-alignment mat-body-2" [innerHTML]="identifyTheftContent1 | sanitizeHtml">
    </div>
    <div role="region" aria-label="Terms and condition" class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical rufId>
      <!-- <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginVertical  rufMarginBottom="medium"> -->
        <mat-checkbox matInput fisStyle  [(ngModel)]="termsnconditions"
          ngDefaultControl> <strong> <p class="chex-text-alignment">
          {{ 'IDENTITYTHEFT.CHXBOXTEXT' | translate }} </p> </strong>
        </mat-checkbox>
    </div>
    <div role="region" aria-label="continue" class="chex-page-left-right-alignment" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical rufId>

        <button class="chex-button-alignment" mat-raised-button fisStyle color="primary" rufId type="submit" [disabled]="!termsnconditions ? 'disabled' : null" (click)="submitclick()">{{ 'BUTTONS.CONTINUE' | translate }}</button>
      </div>
      <!-- class="chex-page-left-right-alignment mat-body-2" -->
      <div  class="chex-page-left-right-alignment mat-body-2" [innerHTML]="identifyTheftContent2 | sanitizeHtml">
      </div>
    </div>
  