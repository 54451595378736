import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { SitecoreService } from '../_services/sitecore/sitecore.service';

@Component({
  selector: 'chexsystem-ws-human-trafficking',
  templateUrl: './human-trafficking.component.html',
  styleUrls: ['./human-trafficking.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HumanTraffickingComponent implements OnInit {
  humanTraffickingContent: string = '';
  isLoader: boolean = false;
  termsnconditions = "";
  submit=this._translate.instant('BUTTONS.SUBMIT');

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('HumanTrafficking');
    this.fetchHumanTraffickingContent();
  }

  submitclick(){
    this.router.navigate( ['human-trafficking-block']);
  }

  fetchHumanTraffickingContent() {
    let humanTraffickingContentPath: string = 'Human Trafficking/Data/Content/Data';
    this.isLoader = true;
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: humanTraffickingContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;
     
      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'human-trafficking') {
          this.humanTraffickingContent = fieldObject.value;
        }
      });
      this.isLoader = false;

    }, 
    error => {
      this.isLoader = false;
    }, 
    ()=>{
      this.isLoader = false;
    }
    );
  } 


}
