import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ruf/idp-auth';
import { IdpService } from '../../../_services/registration/idp.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatSidenav } from '@angular/material/sidenav';
import { Registrationresource } from '../../../_models/registration/registrationresource.model';
import { RufDynamicMenubarItem } from '@ruf/shell';
import { environment } from '../../../../environments/environment';
import { DashboardService } from '../../../_services/dashboard-services/dashboard.service';
import { GlobalVariables } from '../../enums/global_variables';
import { PersonalInformationConfirmationService } from '../../../_services/shared/pi-confirmation.service';
import moment from 'moment';

@Component({
  selector: 'after-login-header',
  templateUrl: './after-login-header.component.html',
  styleUrls: ['./after-login-header.component.scss'],
})
export class AfterLoginTopHeaderComponent {
  logoUrl = 'assets/chex.png';
  profile_img_url = 'assets/profile_avtar.jpg';
  selectedProfileImage: any;
  _userName: string;
  iconItems = [
    { text: 'Change Password' },
    { text: 'Profile Settings' },
    { text: 'Logout' },
  ];
  selected = '';
  sidemenuItems: RufDynamicMenubarItem[] =
    Registrationresource.Chexsystem_MenuItem;
  @ViewChild('examplebasicsidenav') sidenav: MatSidenav;
  selectedPath: string = 'dashboard';
  educationURLAfterLogin: string = environment.educationURLAfterLogin;
  menuState: boolean = true;

  constructor(
    private router: Router,
    private auth: AuthService,
    private idpService: IdpService,
    private sanitizer: DomSanitizer,
    private dashboardService: DashboardService,
    private piConfirmService: PersonalInformationConfirmationService
  ) {
    this._userName = GlobalVariables.globalvars.get('UserId') || '';
    this.getProfileImage(this._userName);
    piConfirmService.confirmPIEvent$.subscribe((item) =>
      this.setMenuState(item)
    );
  }

  userSelect(redirectLink: string) {
    if (redirectLink === 'Change Password') {
      this.router.navigate(['changepassword']);
    } else if (redirectLink === 'Profile Settings') {
      this.router.navigate(['userprofile']);
    } else if (redirectLink === 'Logout') {
      GlobalVariables.globalvars.clear();
      localStorage.clear();
      sessionStorage.removeItem('ProfileConfirmed');
      // localStorage.removeItem('ProfileConfirmed');
      // localStorage.removeItem('isReg');
      // localStorage.removeItem('isLoggedin');

      this.auth.isLoggedIn = false;
      let qp = moment().unix();
      let logoutUrl =
        environment.globalLogoutUrl +
        environment.auth.clientId +
        '&random=' +
        qp;
      window.location.href = logoutUrl;
    } else if (redirectLink === 'search') {
      this.router.navigate(['sitecoresearch']);
    }
  }

  getProfileImage(loginName: string) {
    this.idpService.getUserProfilePic(loginName).subscribe((data: any) => {
      const imgBlob = new Blob([data], { type: 'image/png' });
      this.createImageFromBlob(imgBlob);
    });
  }
  createImageFromBlob(image: any) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.selectedProfileImage = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  openbasic(event: any) {
    this.selectedPath = event.path;
    if (event.path === '#/dashboard') {
      this.router.navigate(['dashboard']);
    }
    if (event.path === '#/disclosure-report-info') {
      //this.router.navigate( ['pdf-view-docs', {src: "disclosure", id: 23343432432}]);
      this.router.navigate(['disclosure-report-info']);
    } else if (event.path === '#/consumer-score-page') {
      this.router.navigate(['consumer-score-page']);
    } else if (event.path === '#/discloser-dispute-trans') {
      this.router.navigate(['discloser-dispute-trans']);
    } else if (event.path === '#/opt-out') {
      this.router.navigate(['opt-out']);
    } else if (event.path === '#/manage-sec-frz') {
      this.router.navigate(['manage-sec-frz']);
    } else if (event.path === '#/security-freeze-info') {
      this.router.navigate(['security-freeze-info']);
    } else if (event.path === '#/place-freeze') {
      this.router.navigate(['place-freeze']);
    } else if (event.path === '#/replace-sec-pin') {
      this.router.navigate(['replace-sec-pin']);
    } else if (event.path === '#/security-freeze-notice') {
      this.router.navigate(['security-freeze-notice']);
    } else if (event.path === this.educationURLAfterLogin) {
      let winRef = window.open(this.educationURLAfterLogin, '_blank', 'noopener,noreferrer');
    } else if (event.path === '#/place-alert') {
      this.router.navigate(['place-alert']);
    } else if(event.path === '#/human-trafficking'){
      this.router.navigate(['human-trafficking']);
    } else if (event.path === '#/identity-theft-info') {
      this.router.navigate(['identity-theft-info']);
    } else if(event.path== "#/id-tft") {
      this.router.navigate( ['id-tft']);
    } 
    this.sidenav.toggle();
  }

  setMenuState(val: boolean) {
    this.menuState = val;
  }

  close(reason: string) {
    this.sidenav.close();
  }

  hideSideMenu(): void {
    this.sidenav.close();
  }
}
