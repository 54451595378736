import { Injectable } from '@angular/core';
import { scoreReq } from '../../_models/classes/consumer-score-disclosure-request.model';
import { scoreRes } from '../../_models/classes/consumer-score-disclosure-response.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { ApiService } from '../shared/api.service';

@Injectable({
  providedIn: 'root'
})

export class ConsumerScoreDisclosureService {

  private readonly Api_Base_URL = environment.apiurl;
  constructor(private httpClient : HttpClient, private _apiService: ApiService) { 
   
  }

  /**
   * get the freeze details
   * @param scoreRequest
   * @returns response of api
   */
   getConsumerDisclosureScore(scoreRequest: scoreReq.consumerScoreData):Observable<scoreRes.consumerScoreDisclosure>{
    const url = this.Api_Base_URL.concat('/ConsumerScore');
    return this._apiService.post<scoreRes.consumerScoreDisclosure>(url,scoreRequest);
    // .pipe(      
    //   catchError(this.handleError)
    // );
   }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string = `Backend returned code ${error.status}, body was: ${error.error}`;
    return throwError(() => new Error(errorMessage));
  }

}
