import { RufDynamicMenubarItem } from "@ruf/shell";
import { environment } from '../../../environments/environment';

export class Registrationresource {

    public static ida_quiz_title: string = "Identity Protection Questions";
    public static challenge_quiz_title: string = "Identity Protection Questions Challenge Quiz";
    public static ida_quiz_timer_seconds:number = 600; // 10 minutes
    public static challenge_quiz_timer_seconds:number = 240; // 4 minutes
    public static quiz_validation_message = "Your registration request cannot be completed due to authentication failure. Please try again.";
    public static quiz_time_exceed_message ="You have exceeded the time limit to complete the security quiz. Please try again.";
    public static ida_quiz_subtitle = "In an effort to protect your identity, please answer the following security questions.";
    public static challenge_quiz_subtitle = "For additional security, please answer the following questions.";
    public static cancel_registration_title = "Cancel Registration";
    public static age_validation_title = "Age Verification";
    public static unexpected_error_title = "Unexpected Error";
    public static request_could_not_processed = "Your Request Could Not be Processed";
    public static registration_complete_title = "Registration Complete";
    public static registration_failed_title = "Registration Failed";
    public static changepassword_failed_title = "Change Password Failed";
    public static changepassword_complete_title = "Change Password Complete";
    public static changepassword_prev_password_error = "The New Password does not follow password history rules, it must be different from the last 10 previously created password(s)."
    public static invalidErrorMessage: {[key: string]: string} = {
        name            : "Valid characters include letters and the following special characters: dash (-) and spaces. Minimum length is 2 characters and maximum 20 characters.",
        middleName      : "Valid characters include letters and the following special characters: dash (-) and spaces. Minimum length is 1 character and maximum 15 characters.",
        city            : "Valid characters include letters and spaces. Punctuation or special characters (such as !, @, #) not allowed in the city field. Minimum length is 2 characters and maximum 30 characters.",
        zipCode         : "Zip Code is required. 5 digit numeric value must be given.",
        userName        : "The Username must contain the following: 8 – 25 characters The Username must not be an email-address.",
        passwordText    : "The following rules apply for Password and Verify Password: The Password must contain the following 12 – 32 characters Uppercase letters, lowercase letters, numbers, special characters. (Special characters include one of the following ! @ # $ % ^ & +) The Password cannot contain your Username, in forward or reverse order. The Password cannot contain your First, Middle or Last name, in forward or reverse order.",
        changepassword  : "The following rules apply for Current Password, New Password and Confirm New Password: The Password must contain the following 12 – 32 characters Uppercase letters, lowercase letters, numbers, special characters. (Special characters include one of the following ! @ # $ % ^ & +) The Password cannot contain your Username, in forward or reverse order. The Password cannot contain your First, Middle or Last name, in forward or reverse order.",
        currentpassword  : "The following rules apply for Current Password, New Password and Confirm New Password: The Password must contain the following 8 – 32 characters Uppercase letters, lowercase letters, numbers, special characters. (Special characters include one of the following ! @ # $ % ^ & +) The Password cannot contain your Username, in forward or reverse order. The Password cannot contain your First, Middle or Last name, in forward or reverse order.",
        email           : "Valid characters include letters, numbers and the following special characters: at symbol (@), underscore (_) and dash (-). Minimum length is 4 characters and maximum 50 characters.",
        driverLicense   : "Please provide your state-issued ID number. If providing a military ID number, select “MY” as the state of issuance.",
        address         : "Valid characters include letters, numbers and spaces. Punctuation or special characters (such as !, @, #) not allowed in the address field. Minimum length is 2 characters and maximum 50 characters.",
        dateOfBirth     : "Date of Birth is invalid. Date must be in MM/DD/YYYY format and the age should be 18 years or older.",
        addressLine1Required    :"Previous Address Line1 is required. Minimum length is 2 characters and maximum 50 characters.",
        addressLine2Required    :"Previous Address Line2 is required. Minimum length is 2 characters and maximum 50 characters.",
        cityRequired            :"City is required. Minimum length is 2 characters and maximum 30 characters.",
        stateRequired           :"State is required.",
        zipCodeRequired         :"Zip Code is required. 5 digit numeric value must be given.",
        lastname                : "Valid characters include letters and the following special characters: apostrophe ('), dash (-), period (.) and spaces. Minimum length is 2 characters and maximum 30 characters.",
        stateZipCodeRange       : "Invalid Zip Code entered for the selected State."
    };
    public static userprofile_complete_title = "Update Profile Complete";
    public static uniqueEmailTitle = "Email Validation";
    public static uniqueEmailText = "Email address must be unique. This email address is already in use.";
    public static idaPopupMessageDetail: {[key: string]: string} = {
        title : "Identity Protection Questions",
        message: "In an effort to protect your identity, please answer all of the security questions. You have #minutes# minutes #seconds# seconds left.",
        buttonText: "Ok"
    };
    public static idaVelocityWarningDetail: {[key: string]: string} = {
      title : "Your request could not be processed",
      vel_message : "Your security quiz limit has been exceeded. Please contact ChexSystems to submit your request using another method.",
      buttonText: "Ok",
      idaQuestionsNotGenerate: "A security quiz based on the information you provided could not be generated. Please contact ChexSystems to submit your request using another method.",
      unexpectedError: "An unexpected error has occurred and we were unable to generate a security quiz. Please contact ChexSystems to submit your request using another method.",
      insufficientDataChallenge: "The IDA transaction could not be processed successfully. Insufficient Data for Challenge Questions."
  };

    public static idaQuizNotGenerated: {[key:string]:string} = {
      title : "Challenge Quiz Not Generated",
      buttonText: "Ok",
      message1: "A challenge quiz based on the information you provided could not be generated. Please contact ",
      message2: " to submit your request using another method."

    };

    public static quiz_time_exceed_message_title ="Identity Protection Questions Timeout";
    public static educationURLAfterLogin: string = environment.educationURLAfterLogin;
    public static Chexsystem_MenuItem: RufDynamicMenubarItem[] = [
        {
          label: 'Home',
          path: '#/dashboard',
        },
        {
          label: 'Request Reports',
          path: '#/RequestReports',
          children: [
            {
              label: 'Consumer Disclosure',
              path: '#/disclosure-report-info',
            },
            {
              label: 'Consumer Score Report',
              path: '#/consumer-score-page',
            }
          ]
        },
        {
          label: 'Identity Theft',
          path: '#/IdentityTheft',
          children: [
            {
              label: 'Identity Theft Information',
              path: '#/identity-theft-info'
            },
            {
              label: 'Identity Theft Block',
              path: '#/id-tft'
            },
            {
              label: 'Security Alert',
              path: '#/place-alert'
            }
          ]
        },
        {
          label: 'Human Trafficking',
          path: '#/human-trafficking'
        },
        {
          label: 'Security Freeze',
          path: '#/SecurityFreeze',
          children: [
            {
              label: 'Security Freeze Information',
              path: '#/security-freeze-info'
            },
            {
              label: 'Place a Freeze',
              path: '#/place-freeze'
            },
            {
              label: 'Manage Security Freeze',
              path: '#/manage-sec-frz'
            },
            {
              label: 'Replacement PIN',
              path: '#/replace-sec-pin'
            },
            {
              label: 'Notice of Rights',
              path: '#/security-freeze-notice'
            }
          ]
        },    
        {
          label: 'Education',
          path: this.educationURLAfterLogin,
        },        
        {
          label: 'Dispute',
          path: '#/discloser-dispute-trans',
        },
        {
          label: 'Opt Out',
          path: '#/opt-out',
        }    
      ];
    public static DuplicateUserRegistration: string ="The information you entered matches an existing user. Please click <a href='#/dashboard' > here </a> to login. <br/>You will receive a Portal welcome email. Please disregard this email as the registration was not successful.";
    public static DuplicateUserRegOnPage1: string = "An account already exists for the user information provided.  Please click <a href='#/dashboard' > Login Now </a> to access your account. <br />If you do not remember your username or password, select “Trouble signing in?” and follow the prompts.  <br />If you believe this is an error, please contact ChexSystems.";
    public static DuplicateUserUpdate: string ="The information you entered matches an existing user. Please contact ChexSystems at 800.428.9623 if you believe this is an error.";
    public static SplHandlingCnsmr: string = "We are unable to process your registration. For assistance, you may contact ChexSystems at 800.428.9623 and provide message code SH001.";
    // public static SplHandlingCnsmr2: string = "at 800.428.9623 for assistance.";
    public static IsMockIdaQuiz:boolean = false;
    public static HttpErrorCodes = [500, 410, 409, 404, 403, 401, 400];
    public static InvalidNames = ['same','name','first','last','middle','anyone','anyname','someone','somename','the','same as above','current','resident','none','n/a','not applicable','att of','attof','attorney','lawyer','unknown','test','fake','jr','sr','III','dr','III','mr','mrs','IV','na'];
    public static InvalidSSN = ['123-45-6789', '987-65-4321', '000-00-0000', '111-11-1111', '222-22-2222', '333-33-3333', '444-44-4444', '555-55-5555', '666-66-6666', '777-77-7777', '888-88-8888', '999-99-9999'];
    public static InvalidPhoneNumber = ['123-456-7890', '098-765-4321', '000-000-0000', '111-111-1111', '222-222-2222', '333-333-3333', '444-444-4444', '555-555-5555', '666-666-6666', '777-777-7777', '888-888-8888', '999-999-9999'];
    public static InvalidChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_'];
    public static Timezone = "CST";
    public static PagesToBeRouted = ['#/dashboard', '#/disclosure-report-info', '#/consumer-score-page', '#/identity-theft-info', '#/place-alert','#/security-freeze-info','#/place-freeze','#/manage-sec-frz','#/replace-sec-pin','#/security-freeze-notice','#/discloser-dispute-trans','#/opt-out'];
}
