<div class="chex-bottom_space">
  <ruf-page-header role="region" aria-label="title" rufId="page_header_disputeTab_title" title="{{ title}}"
    description="">

    <ruf-page-header-row rufId="ruf-page-header_menu_row">
      <div class="chex-page-left-right-alignment" fxLayout.gt-md="row" fxLayout="column"
        fxLayoutAlign="space-between stretch">
        <h2 role="region" aria-label="consumerid" class="ruf-caption-2">Consumer ID {{cddData.consumerid}} for {{cddData.name}}</h2>
        <button title="{{!(this.isHumanTrafficking || this.isIdentityTheft) ? 'Items to Dispute': 'Items to Block'}}" *ngIf="disputeRaisedCount > 0" rufId mat-button="" id="secondary" fisStyle
          color="secondary" (click)="showDisputeRaised()">
          <div fxLayout="row">
            <mat-icon role="region" aria-label="items" class="chex-dispute-list-icon" rufIconStyle="sm" fisIcon="menu-list" rufId="cart_icon"></mat-icon>
            &nbsp;&nbsp;&nbsp; <div [matBadge]="disputeRaisedCount">{{ items }}
            </div>
          </div>
        </button>


      </div>
      <!-- <div style="height: 10px;"></div> -->

    </ruf-page-header-row>
  </ruf-page-header>
  <ruf-dynamic-menubar [selectedPath]="selectedPath" (selectChange)="openbasic($event)"
  rufId="dynamic_menubar_disputetabs" [items]="(isHumanTrafficking || isIdentityTheft) ? menuItemsForHT :menuItemsForDispute">
  </ruf-dynamic-menubar>
  <section rufScrollbar *ngIf="selectedPath === 'PersonalInformation' && !(isHumanTrafficking || isIdentityTheft)">
    <div style="width:100%;">
      <p role="region" id="info1" aria-label="closuredispute" class="chex-page-left-right-alignment mat-body-2" style="margin-left: 3%;">{{ 'CONSUMERDISCLOSUREDISPUTE.INFO1'
        | translate }}</p>

      <div class="chex-segment-section" rufMarginBottom>
        <ruf-dynamic-segment rufId="dynamic_segment_items" [items]="segmentItems" [verticalOverflow]="true"
          (select)="selectedSegmentItem = $event.path" [showActiveItemClip]="true" [selectedPath]="selectedSegmentItem">
        </ruf-dynamic-segment>
      </div>
      <!--########## Section Name Start ############-->
      <section class="chex-segment-section" rufScrollbar *ngIf="selectedSegmentItem === 'Name'">
        <div style="width:100%;">
          <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
            [data]="cddData.personalInfo.DisputeName" [metadata]="columnsName">
            <ng-container *rufNoDataRow>
              <mat-icon role="presentation" rufid="" fisicon="information-solid"
                class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
                aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
                data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle="">
              </mat-icon>
              <h2 role="region" id="noitems" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
            </ng-container>
            <ng-container matColumnDef="name">
              <th role="region" id="name" aria-label="name" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.NAME' | translate }}</th>
              <td class="chex-hand-cursor chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="action" stickyEnd>
              <th role="region" id="action" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
              <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
                <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeClick('pi-name', element);"
                  mat-button="" id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
                  <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                    fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
                  </mat-icon>{{element.SectionButtonText}}
                </a>
              </td>
            </ng-container>
          </ruf-dynamic-datatable>
        </div>
      </section>
      <!--########## Section Name End ############-->
      <!--########## Section Address Start ############-->
      <section class="chex-segment-section" rufScrollbar *ngIf="selectedSegmentItem === 'Address'">
        <div style="width:100%;">
          <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
            [data]="cddData.personalInfo.DisputeAddress" [metadata]="columnsAddress">
            <ng-container *rufNoDataRow>
              <mat-icon role="presentation" rufid="" fisicon="information-solid"
                class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
                aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
                data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle="">
              </mat-icon>
              <h2 role="region" id="noitems1" aria-label="noitems1" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
            </ng-container>
            <ng-container matColumnDef="address">
              <th role="region" id="address" aria-label="address" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ADDRESS' | translate }}</th>
              <td class="chex-hand-cursor chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.address}}
              </td>
            </ng-container>
            <ng-container matColumnDef="action" stickyEnd>
              <th role="region" id="action1" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
              <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
                <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeClick('pi-address', element); "
                  mat-button="" id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
                  <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                    fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
                  </mat-icon>{{element.SectionButtonText}}
                </a>
              </td>
            </ng-container>
          </ruf-dynamic-datatable>
        </div>
      </section>
      <!--########## Section Address End ############-->
      <!--########## Section Email Start ############-->
      <section class="chex-segment-section" rufScrollbar *ngIf="selectedSegmentItem === 'EmailAddress'">
        <div style="width:100%;">
          <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
            [data]="cddData.personalInfo.DisputeEmailAddress" [metadata]="columnsEmail">
            <ng-container *rufNoDataRow>
              <mat-icon role="presentation" rufid="" fisicon="information-solid"
                class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
                aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
                data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle="">
              </mat-icon>
              <h2 role="region" id="noitems2" aria-label="noitems2" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
            </ng-container>
            <ng-container matColumnDef="emailAddress">
              <th role="region" id="email" aria-label="email" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.EMAIL' | translate }}</th>
              <td class="chex-hand-cursor chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.emailAddress}}
              </td>
            </ng-container>
            <ng-container matColumnDef="action" stickyEnd>
              <th role="region" id="action2" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
              <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
                <a  rufId (click)="disputeClick('pi-email', element, !element.isSectionDisputable); " mat-button=""
                  id="anchor" [disabled]="(!element.isSectionDisputable || element.isSectionDisputed === true)" fisStyle
                  color="primary">
                  <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                    fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
                  </mat-icon>{{element.SectionButtonText}}
                </a>
              </td>
            </ng-container>
          </ruf-dynamic-datatable>
        </div>
      </section>
      <!--########## Section Email End ############-->
      <!--########## Section Phone Start ############-->
      <section class="chex-segment-section" rufScrollbar *ngIf="selectedSegmentItem === 'Phone'">
        <div style="width:100%;">
          <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
            [data]="cddData.personalInfo.DisputePhone" [metadata]="columnsPhone">
            <ng-container *rufNoDataRow>
              <mat-icon role="presentation" rufid="" fisicon="information-solid"
                class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
                aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
                data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle="">
              </mat-icon>
              <h2 role="region" id="noitems3" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
              <th role="region" id="phone" aria-label="phone" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.PHONE' | translate }}</th>
              <td role="region" aria-label="phoneelement" class="chex-hand-cursor chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.phoneNumber}}
              </td>
            </ng-container>
            <ng-container matColumnDef="phntype">
              <th role="region" id="phntype" aria-label="phntype" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.PHONE TYPE' | translate }}</th>
              <td role="region" aria-label="phntypeelement" class="chex-hand-cursor chx-datagrid-cell" mat-cell *matCellDef="let element">{{element.phntype}}
              </td>
            </ng-container>
            <ng-container matColumnDef="action" stickyEnd>
              <th role="region" id="action3" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
                *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
              <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
                <a  rufId (click)="disputeClick('pi-phone', element, !element.isSectionDisputable); " mat-button=""
                  id="anchor" [disabled]="(!element.isSectionDisputable || element.isSectionDisputed === true)" fisStyle
                  color="primary">
                  <mat-icon role="region" aria-label="actionelement" fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                    fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
                  </mat-icon>{{element.SectionButtonText}}
                </a>
              </td>
            </ng-container>
          </ruf-dynamic-datatable>
        </div>
      </section>
      <!--########## Section Phone End ############-->
      <!--########## Section DOB Start ############-->
      <section class="chex-segment-section" rufScrollbar *ngIf="selectedSegmentItem === 'DateofBirth'">
        <div class="chex-table_1" fxLayout.gt-md="row" fxLayout="column">
          <div fxLayout="column" fxFlex="auto">
            <div class="chex-dispute-pi-th1">
              <p role="region" id="dob" aria-label="dob" class="chex-dispute-pi-caption mat-subheading-1">{{ 'CONSUMERDISCLOSUREDISPUTE.DOB' | translate }}</p>
            </div>
            <div>
              <p role="region" aria-label="dobelement" class="chex-dispute-pi-td-child mat-subheading-1">{{cddData.personalInfo.dateOfBirth |
                date:'MM/dd/yyyy'}}</p>
              &nbsp;
              <a  class="chex-dispute-anchor-tag" *ngIf="this.cddData.personalInfo.dateOfBirthIsDisputable===true" rufId
                (click)="disputeClick('pi-dob', null); " mat-button="" id="anchor"
                [disabled]="this.cddData.personalInfo.dateOfBirthIsDisputed === true" fisStyle color="primary">
                <mat-icon fisIcon="posak" rufIconStyle="xs"
                  *ngIf="this.cddData.personalInfo.dateOfBirthIsDisputed === true" rufId fisStyle
                  fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
                </mat-icon>{{cddData.personalInfo.dateOfBirthButtonText}}
              </a>
            </div>
          </div>
          <div fxLayout="column" fxFlex="auto">

          </div>
        </div>
      </section>
      <!--########## Section DOB End ############-->
    </div>
    <!-- <div style="height: 10px;"></div> -->
  </section>
  <section class="chex-menu-section" rufScrollbar *ngIf="selectedPath === 'ReportedInformation'">
    <div style="width:100%;">
      <h4 role="region" id="reportedinfo" aria-label="reportedinfo" class="ruf-caption-2">{{ 'CONSUMERDISCLOSUREDISPUTE.REPORTEDINFO' | translate }}</h4>
      <!-- 
                  -------------------------------------------------------------
                -->
      <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
        [data]="cddData.reportedInfo.reportedInfoItemList" [metadata]="columns">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid"
            class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
            aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
            data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" id="noitems4" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="sourceOfInformation">
          <th role="region" id="source" aria-label="source" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.SOURCE' | translate }}</th>
          <td role="region" aria-label="sourceinfo" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell
            *matCellDef="let element">{{element.sourceOfInformation}} </td>
        </ng-container>
        <ng-container matColumnDef="dateofClosure">
          <th role="region" id="dateclosure" aria-label="dateclosure" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.DATECLOSURE' | translate }}</th>
          <td role="region" aria-label="dateclosureelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell
            *matCellDef="let element">{{element.dateofClosure | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="closureStatus">
          <th role="region" id="closurestatus" aria-label="closurestatus" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.CLOSURESTATUS' | translate }}</th>
          <td role="region" aria-label="closurestatuselement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('rpti', element)" mat-cell
            *matCellDef="let element">{{element.closureStatus}} </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th role="region" id="action4" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
          <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
            <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeRptiClick('RPTI', element); "
              mat-button="" id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
              <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
              </mat-icon>{{element.SectionButtonText}}
            </a>
          </td>
        </ng-container>
      </ruf-dynamic-datatable>
      <!------------------------------------------------------------------
                -->
    </div>
  </section>
  <section class="chex-menu-section" rufScrollbar *ngIf="selectedPath === 'InquiriesViewedByOthers'">
    <div style="width:100%;">
      <h4 role="region" id="inquiries" aria-label="inquiries" class="ruf-caption-2">{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRIES' | translate }}</h4>
      <!--
                  -------------------------------------------------------------
                -->

      <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
        [data]="cddData.inquiriesVBO.inquiriesViewedByOthersItemList" [metadata]="columnsIVBO">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid"
            class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
            aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
            data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" id="noitems5" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="name">
          <th role="region" id="name1" aria-label="name" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRERNAME' | translate }}</th>
          <td role="region" aria-label="nameelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('ivbo', element)" mat-cell
            *matCellDef="let element">{{element.inquirerName}}</td>
        </ng-container>
        <ng-container matColumnDef="inquiryDate">
          <th role="region" id="inquirydate" aria-label="inquirydate" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRYDATE' | translate }}</th>
          <td role="region" aria-label="inquirydateelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('ivbo', element)" mat-cell
            *matCellDef="let element">{{element.inquiryDate | date:'MM/dd/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th role="region" id="action5" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
          <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
            <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeIVBOClick('IVBO', element); "
              mat-button="" id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
              <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
              </mat-icon>{{element.SectionButtonText}}
            </a>
          </td>
        </ng-container>
      </ruf-dynamic-datatable>
      <!------------------------------------------------------------------
                -->
    </div>
  </section>
  <section class="chex-menu-section" rufScrollbar *ngIf="selectedPath === 'CheckCashingInquiries'">
    <div style="width:100%;">
      <h4 role="region" id="check" aria-label="check" class="ruf-caption-2">{{ 'CONSUMERDISCLOSUREDISPUTE.CHECK' | translate }}</h4>
      <!--
                  -------------------------------------------------------------
                -->

      <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
        [data]="cddData.checkCashingInq.checkCashingInquiriesItemList" [metadata]="columnsCCI">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid"
            class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
            aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
            data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" id="noitems6" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="inquirer">
          <th role="region" id="inquirer" aria-label="inquirer" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRER' | translate }}</th>
          <td role="region" aria-label="inquirerelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('cci', element)" mat-cell
            *matCellDef="let element">{{element.inquirer}} </td>
        </ng-container>
        <ng-container matColumnDef="inquiryDate">
          <th role="region" id="date" aria-label="date" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.DATE' | translate }}</th>
          <td role="region" aria-label="inquirydateelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('cci', element)" mat-cell
            *matCellDef="let element">{{element.inquiryDate | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th role="region" id="action6" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
          <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
            <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeCCIClick('CCI', element); " mat-button=""
              id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
              <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
              </mat-icon>{{element.SectionButtonText}}
            </a>
          </td>
        </ng-container>
      </ruf-dynamic-datatable>
      <!------------------------------------------------------------------
                -->
    </div>
  </section>
  <section class="chex-menu-section" rufScrollbar *ngIf="selectedPath === 'InquiriesViewedByYou'">
    <div style="width:100%;">
      <p role="region" *ngIf="!(isHumanTrafficking || isIdentityTheft)" id="info1" aria-label="closuredispute" class=" mat-body-2" >{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRIESVIEWDATA'
        | translate }}</p>
      <h4 role="region" id="inquiriesview" aria-label="inquiriesview" class="ruf-caption-2">{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRIESVIEW' | translate }}</h4>
      <!--
                  -------------------------------------------------------------
                -->

      <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
        [data]="cddData.inquiriesVBY.inquiriesViewedByOthersItemList" [metadata]="(isHumanTrafficking || isIdentityTheft) ? columnsIVBO: columnsIVBY ">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid"
            class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
            aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
            data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" id="noitems7" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="name">
          <th role="region" id="name2" aria-label="name" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.INQUIRERNAME' | translate }}</th>
          <td role="region" aria-label="nameelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('ivby', element)" mat-cell
            *matCellDef="let element">{{element.inquirerName}}</td>
        </ng-container>
        <ng-container matColumnDef="inquiryDate">
          <th role="region" id="date1" aria-label="date" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.DATE' | translate }}</th>
          <td role="region" aria-label="dateelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('ivby', element)" mat-cell
            *matCellDef="let element">{{element.inquiryDate | date:'MM/dd/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="action" *ngIf = "(isHumanTrafficking || isIdentityTheft)">
          <th role="region" id="action7" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
          <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
            <a  *ngIf="element.isSectionDisputable===true " rufId (click)="disputeIVBYClick('IVBY', element); "
              mat-button="" id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
              <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
              </mat-icon>{{element.SectionButtonText}}
            </a>
          </td>
        </ng-container>
      </ruf-dynamic-datatable>
      <!------------------------------------------------------------------
                -->
    </div>
  </section>
  <section class="chex-menu-section" rufScrollbar *ngIf="selectedPath === 'RetailInformation'">
    <div style="width:100%;">
      <h4 role="region" id="retail" aria-label="retail" class="ruf-caption-2">{{ 'CONSUMERDISCLOSUREDISPUTE.RETAIL' | translate }}</h4>
      <!--
                  -------------------------------------------------------------
                -->

      <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
        [data]="cddData.retailInfo.retailInformatioItemList" [metadata]="columnsRI">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid"
            class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
            aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
            data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" id="noitems8" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="payee">
          <th role="region" id="payee" aria-label="payee" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.PAYEE' | translate }}</th>
          <td role="region" aria-label="payelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('rti', element)" mat-cell
            *matCellDef="let element">{{element.payee}} </td>
        </ng-container>
        <ng-container matColumnDef="amountOfCheck">
          <th role="region" id="amount" aria-label="amount" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.AMOUNT' | translate }}</th>
          <td role="region" aria-label="amountelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('rti', element)" mat-cell
            *matCellDef="let element">{{element.amountOfCheck}} </td>
        </ng-container>
        <ng-container matColumnDef="dateofCheck">
          <th role="region" id="date2" aria-label="date" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.DATE' | translate }}</th>
          <td role="region" aria-label="datecheck" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('rti', element)" mat-cell
            *matCellDef="let element">{{element.dateofCheck | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th role="region" id="action" aria-label="action8" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
          <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
            <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeRIClick('RI', element); " mat-button=""
              id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
              <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
              </mat-icon>{{element.SectionButtonText}}
            </a>
          </td>
        </ng-container>
      </ruf-dynamic-datatable>
      <!------------------------------------------------------------------
                -->
    </div>
  </section>
  <section class="chex-menu-section" rufScrollbar *ngIf="selectedPath === 'HistoryofChecksOrdered'">
    <div>
      <h4 role="region" id="history" aria-label="history" class="ruf-caption-2">{{ 'CONSUMERDISCLOSUREDISPUTE.HISTORY' | translate }}</h4>
      <!--
                  -------------------------------------------------------------
                -->

      <ruf-dynamic-datatable [showLoadingIndicator]="showLoadingIndicator" #ddt [showFilter]="false" [pageSizes]="[5, 10]" (stateChange)="onStateChange($event)"
        [data]="cddData.historyofCO.historyofChecksOrderedItemList" [metadata]="columnsHCO">
        <ng-container *rufNoDataRow>
          <mat-icon role="presentation" rufid="" fisicon="information-solid"
            class="mat-icon notranslate ruf-datatable-no-data-message-icon fisfont fis-icon-information-solid mat-icon-no-color ng-star-inserted"
            aria-hidden="true" ng-reflect-fis-icon="information-solid" data-mat-icon-type="font"
            data-mat-icon-name="fis-icon-information-solid" data-mat-icon-namespace="fisfont" fisstyle=""></mat-icon>
          <h2 role="region" id="noitems9" aria-label="noitems" class="chex-no-record-message">{{ 'CONSUMERDISCLOSUREDISPUTE.NOITEMS' | translate }}</h2>
        </ng-container>
        <ng-container matColumnDef="name">
          <th role="region" id="name3" aria-label="name" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.NAME' | translate }}</th>
          <td role="region" aria-label="nameelement" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('hco', element)" mat-cell
            *matCellDef="let element">{{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="dateOrderShipped">
          <th role="region" id="date3" aria-label="date" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.DATE' | translate }}</th>
          <td role="region" aria-label="dateorder" class="chex-hand-cursor chx-datagrid-cell" (click)="rowClickHandler('hco', element)" mat-cell
            *matCellDef="let element">{{element.dateOrderShipped | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th role="region" id="action9" aria-label="action" class="chx-datagrid-cell" [style.width]="ddt.getColumnPropValue('edit', 'width')" mat-header-cell
            *matHeaderCellDef>{{ 'CONSUMERDISCLOSUREDISPUTE.ACTION' | translate }}</th>
          <td class="chx-datagrid-cell" (click)="$event.stopPropagation()" mat-cell *matCellDef="let element">
            <a  *ngIf="element.isSectionDisputable===true" rufId (click)="disputeHCOClick('HCO', element); " mat-button=""
              id="anchor" [disabled]="element.isSectionDisputed === true" fisStyle color="primary">
              <mat-icon fisIcon="posak" rufIconStyle="xs" *ngIf="element.isSectionDisputed === true" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid chex-dispute-complete-icon">
              </mat-icon>{{element.SectionButtonText}}
            </a>
          </td>
        </ng-container>
      </ruf-dynamic-datatable>
      <!------------------------------------------------------------------
                -->
    </div>
  </section>
</div>
<!-- New Implementation ********************************************************* -->
<!-- </ruf-card>
</ruf-app-canvas> -->
