import { Component, ViewChild, TemplateRef, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogUploaderInput, DialogFileUploaderComponent } from '../../../dailog/dialog-attachment/dialog-file-attachment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogInfo, DialogIndividualDisputeComponent } from '../../../dailog/dialog-individual-dispute/dialog-individual-dispute.component';
import { HttpClient } from '@angular/common/http';
import { dashboard } from '../../../_models/classes/dashboard-dispute.model';
import { dashboardEvent } from '../../../_models/classes/dashboard-dispute.model';
import { DashboardService } from '../../../_services/dashboard-services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVariables } from '../../enums/global_variables';
import { RufDropdownType } from '@ruf/shell';

@Component({
  selector: 'chx-dashboard-disputes',
  templateUrl: './dashboard-disputes.component.html',
  styleUrls: ['./dashboard-disputes.component.scss'],
})


export class DashboardDisputesComponent implements OnInit {
  dialogWidth:string = "477px";
  dialogheight: string = "1178px";
  panel = { type: RufDropdownType.PopoverMenu };
  hasBackdrop = true;
  disputeRoot:dashboard.DashboardDisputeRoot;
  disputesTextValue: string='';
  title=this._translate.instant('DASHBOARDDISPUTES.DISPUTES CENTER');
  noitems=this._translate.instant('DASHBOARDDISPUTES.NOITEMS');
  confirmation=this._translate.instant('DASHBOARDDISPUTES.CONFIRMATION');
  date=this._translate.instant('DASHBOARDDISPUTES.DATE');
  targetdate=this._translate.instant('DASHBOARDDISPUTES.TARGETDATE');
  status=this._translate.instant('DASHBOARDDISPUTES.STATUS');
  view=this._translate.instant('DASHBOARDDISPUTES.VIEW');
  showLoadingIndicator:boolean = false;
  @Input()
  get disputesText(): string {
    return this.disputesTextValue;
  }

  set disputesText(value: string) {
    this.disputesTextValue = value;
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    private dashboardService: DashboardService,
    private _translate: TranslateService,
  ) {   }

  ngOnInit(): void {
    this.initData();
  }

  initData(){
    this.showLoadingIndicator = true;
    this.getDisputeRespData().subscribe((res) => {
      this.disputeRoot = res as dashboard.DashboardDisputeRoot;
      var tempItems: DashboardDisputesItems[] = [];
      ///Make the list in decending prder
      this.disputeRoot.viewDisputeListRsp.disputeList = 
          this.disputeRoot.viewDisputeListRsp.disputeList.sort((item1,item2) =>  new Date(item2.dateReceived).getTime() - new Date(item1.dateReceived).getTime());
      var counter:number = 0;
      for (var val of this.disputeRoot.viewDisputeListRsp.disputeList) {
        counter++;
        if(counter <=3){
          var item:DashboardDisputesItems = new DashboardDisputesItems();
          if(val.cnsmrRefId !== ""){
            item.confirmationNo = val.cnsmrRefId;
            if(val.targetCompletionDate.trim() !== ""){
              item.targetCompletionDate = new Date(val.targetCompletionDate);
            }
            
            item.receivedDate = new Date(val.dateReceived);
            item.OverallStatus = val.overallStatus.toUpperCase();
            tempItems.push(item);
          }
          
        } 
      }
      this.items = tempItems;
      //this.dashboardService.triggerDisputeLoadEvent();
    }, 
    error => {
      this.dashboardService.triggerDisputeLoadEvent();
      this.showLoadingIndicator = false;
    },
    () => {
      this.dashboardService.triggerDisputeLoadEvent();
      this.showLoadingIndicator = false;
    });
  }

  getDisputeRespData() {
    let _userName = GlobalVariables.globalvars.get("UserId") || '';
    let dashReqRoot:dashboard.ViewDisputeListReqRoot = new dashboard.ViewDisputeListReqRoot();
    dashReqRoot.viewDisputeListReq = new dashboard.ViewDisputeListReq();
    dashReqRoot.viewDisputeListReq.userName = _userName;
    return this.dashboardService.DisputeItemList(dashReqRoot);
    // return this.http.get('./././assets/jsondata/ViewDisputeList-Rsp.json');
  }
  items: DashboardDisputesItems[] = [];

  openDisputeDetailSingle(item:DashboardDisputesItems){
    //alert(confirNo);
    //this.router.navigate( ['disputedetail', {src: "d", id: confirNo}]);
    
    //this.router.navigateByUrl('/disputedetail')

    const dialogData = new DialogInfo("Dispute Details", item.confirmationNo, item.receivedDate, item.targetCompletionDate);
          const dialogRef = this.dialog.open(DialogIndividualDisputeComponent,{position
              : {top: '0%', right: '0%'},
            width:this.dialogWidth,
            height:this.dialogheight,
            data:dialogData,
            disableClose: true
          }
          );
          dialogRef.afterClosed().subscribe(result => { 
            
          });
  }

  openDisputeDetailAll(confirNo: string){
    //alert(confirNo);
    this.router.navigate( ['disputedetailall']);
    
    //this.router.navigateByUrl('/disputedetail')
  }


    columns: any[] = [
      {
        field: 'confirmationNo',
        name: 'Confirmation#',
        width: '25%',
        title: 'Confirmation#',
        //sticky: true,
        filterable: true
      },
      {
        field: 'receivedDate',
        name: 'Received Date',
        title: 'Received Date',
        width: '25%',
        dataType: {
          name: 'date',
          format: ['MM/dd/yyyy']
        },
        //sticky: true
      },
      {
        field: 'targetCompletionDate',
        name: 'Target Completion Date',
        title: 'Target Completion Date',
        width: '25%',
        dataType: {
          name: 'date',
          format: ['MM/dd/yyyy']
        },
        //sticky: true
      },
      // commented the status field for part of this story US1353281
      // {
      //   field: 'OverallStatus',
      //   name: 'Overall Status',
      //   title: 'Overall Status',
      //   width: '25%',
      // },
      
    ];

    rowSelectionChange(event: any) {
    }
  
    onStateChange(event: any) {
    }
    rowClickHandler(code: string, element:any){
      //this.router.navigate( ['pdf-view-docs', {src: "a", id: this.confNo}]);
    }
    
  }

  export class DashboardDisputesItems {
    confirmationNo: string;
    receivedDate: Date;
    targetCompletionDate: Date;
    OverallStatus: string;
    // desc: string="";
    // status: string="";
    // disDate: string="";
  }