<ruf-page-header role="region" aria-label="title" rufId="" title="{{ 'REGISTRATION.TITLE' | translate }}"
  description=""></ruf-page-header>

<ruf-stepper #rufStepper [color]="bgColor" [showSwitchStepTrigger]="jumpStepTrigger" [progressBarColor]="pbColor"
  [progressBarDirection]="pbDirection" [showProgressBar]="showProgressBar" (stepChange)="onStepSelect($event)">
  <mat-horizontal-stepper linear #stepper (selectionChange)="selectionChange($event)"
    class="chex-page-left-right-alignment"> 
    <mat-step [stepControl]="firstFormGroup">
      <div role="region" aria-label="mandatoryfield" class="mat-body-2 chex-title chex-font">
        {{ 'REGISTRATION.MANDATORY FIELDS TEXT' | translate }}
      </div>
      <form [formGroup]="firstFormGroup" id="firstFormGroup" autocompleteOff>
        <ng-template matStepLabel>Personal Details</ng-template>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="firstname" class="chex-form-field-label">{{ 'REGISTRATION.FIRST NAME'
              | translate }}</mat-label>
            <input id="FirstNameCtrl" minlength="2" maxlength="20" formControlName="fnameCtrl" fisStyle matInput
              placeholder="Enter First Name" pattern="^(?=.+[A-Za-z])[a-zA-Z -]{2,20}$" autocompleteOff />
            <mat-error *ngIf="firstFormGroup.get('fnameCtrl')?.hasError('required')">{{ 'REGISTRATION.FIRST NAME ERR'
              | translate }}</mat-error>
            <mat-error *ngIf="
                  firstFormGroup.get('fnameCtrl')?.errors?.pattern ||
                  firstFormGroup.get('fnameCtrl')?.errors?.name === 'pattern'
                " matTooltip="{{ invalidErrorMessage.name }}" matTooltipPosition="below"
              [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>First Name is invalid. Hover for details.</mat-error>
          </mat-form-field>
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="middlename" class="chex-form-field-label">{{ 'REGISTRATION.MIDDLE
              NAME' | translate }}</mat-label>
            <input id="mnameCtrl" minlength="1" maxlength="15" formControlName="mnameCtrl" fisStyle matInput
              placeholder="Enter Middle Name" pattern="^(?=.*[A-Za-z])[a-zA-Z -]{1,15}$" autocompleteOff />
            <mat-error *ngIf="
                  firstFormGroup.get('mnameCtrl')?.errors?.pattern ||
                  firstFormGroup.get('mnameCtrl')?.errors?.name === 'pattern'
                " matTooltip="{{ invalidErrorMessage.middleName }}" matTooltipPosition="below"
              [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Middle Name is invalid. Hover for details.</mat-error>
          </mat-form-field>
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="lastname" class="chex-form-field-label">{{ 'REGISTRATION.LAST NAME' |
              translate }}</mat-label>
            <input id="lastNameCtrl" minlength="2" maxlength="30" formControlName="lnameCtrl" fisStyle matInput
              placeholder="Enter Last Name" pattern="^(?=.+[A-Za-z])[a-zA-Z.' -]{2,30}$" autocompleteOff />
            <mat-error *ngIf="firstFormGroup.get('lnameCtrl')?.hasError('required')">{{ 'REGISTRATION.LAST NAME ERR' |
              translate }}</mat-error>
            <mat-error *ngIf="
                  firstFormGroup.get('lnameCtrl')?.errors?.pattern ||
                  firstFormGroup.get('lnameCtrl')?.errors?.name === 'pattern'
                " matTooltip="{{ invalidErrorMessage.lastname }}" matTooltipPosition="below"
              [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Last Name is invalid. Hover for details.</mat-error>
          </mat-form-field>
          <mat-form-field class="chex-suffix_dropdown" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="suffix" class="chex-form-field-label">{{ 'REGISTRATION.SUFFIX' |
              translate }}</mat-label>
            <mat-select fisStyle placeholder="Select Suffix" [(ngModel)]="suffixDetail" formControlName="suffix">
              <mat-option *ngFor="let suffix of suffixes" [value]="suffix.value">
                {{ suffix.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always" fisStyle>
            <mat-label role="region" aria-label="prevlastname" class="chex-form-field-label">{{ 'REGISTRATION.PREVIOUS
              LAST NAME' | translate }}</mat-label>
            <input id="previousName" minlength="2" maxlength="30" formControlName="prevlnameCtrl" fisStyle matInput
              placeholder="Enter Previous Last Name" pattern="^(?=.+[A-Za-z])[a-zA-Z.' -]{2,30}$" autocompleteOff />
            <mat-error *ngIf="firstFormGroup.get('prevlnameCtrl')?.errors?.pattern || 
            firstFormGroup.get('prevlnameCtrl')?.errors?.name === 'pattern'" 
              matTooltip="{{ invalidErrorMessage.lastname }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
              matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Last Name is invalid. Hover for details.</mat-error>
          </mat-form-field>
          <mat-form-field class="chex-form-field" floatLabel="always" boxStyle>
            <mat-label role="region" aria-label="dob" class="chex-form-field-label">{{ 'REGISTRATION.DATE OF BIRTH' |
              translate }}</mat-label>
            <input id="DOB" matInput formControlName="dateofbirthCtrl" [matDatepicker]="picker" placeholder="MM/DD/YYYY"
              [(ngModel)]="birthdate" [disabled]="datepickerDisabled" (dateChange)="dateChanged($event)"
              [matDatepickerFilter]="featureDatesFilter" autocompleteOff />
            <mat-datepicker-toggle fisStyle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker panelClass="fis-style" fisStyle #picker></mat-datepicker>
            <mat-error *ngIf="
                  firstFormGroup.get('dateofbirthCtrl')?.hasError('required')
                ">{{ 'REGISTRATION.DATE OF BIRTH ERR' | translate }}</mat-error>
            <mat-error *ngIf="
                  firstFormGroup.get('dateofbirthCtrl')?.errors?.isInValid ||
                  firstFormGroup.get('dateofbirthCtrl')?.errors?.format || firstFormGroup.get('dateofbirthCtrl')?.errors?.matDatepickerFilter
                " matTooltip="{{ invalidErrorMessage.dateOfBirth }}" matTooltipPosition="below"
              [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Invalid Date of Birth. Hover for details.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="medium">
          <div class="chex-form-ssn-field">
            <mat-form-field class="chex-form-field" floatLabel="always" boxStyle>
              <mat-label role="region" aria-label="ussocialsecuritynumber" class="chex-form-field-label" fisStyle>{{
                'REGISTRATION.U.S.SOCIAL SECURITY NUMBER' | translate }}</mat-label>
              <chexsystem-ws-phonecontrol id="ssnCtrl1" #ssnCtrl1 formControlName="ssnCtrl" required matInput boxStyle
                [inputtype]="'password'" [part1maxlength]="3" [part2maxlength]="2" [part3maxlength]="4"
                [part1placeholder]="'xxx'" [part2placeholder]="'xx'" [part3placeholder]="'xxxx'"
                (errorMessage)="addSSNErrorMessage($event)"></chexsystem-ws-phonecontrol>
              <mat-error class="mat-body-1" *ngIf="
                  !ssnminlength && !ssninvalid &&
                  firstFormGroup.controls['ssnCtrl']?.errors?.required
                ">{{ 'REGISTRATION.U.S.SOCIAL SECURITY NUMBER ERR' | translate }}</mat-error>
              <mat-error class="mat-body-1" *ngIf="ssnminlength">{{ 'REGISTRATION.U.S.SOCIAL SECURITY NUMBER MINLENGTH
                ERR' | translate }}</mat-error>
              <mat-error class="mat-body-1" *ngIf="ssninvalid">{{ 'REGISTRATION.U.S.SOCIAL SECURITY NUMBER INVALID ERR'
                | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="chex-form-ssn-field">
            <mat-form-field class="chex-form-field" floatLabel="always" boxStyle>
              <mat-label role="region" aria-label="verifysocialnumber" class="chex-form-field-label">{{
                'REGISTRATION.VERIFY U.S. SOCIAL SECURITY NUMBER' | translate }}</mat-label>
              <chexsystem-ws-phonecontrol id="verifyssnCtrl1" #verifyssnCtrl1 formControlName="verifyssnCtrl" required
                matInput boxStyle [inputtype]="'password'" [part1maxlength]="3" [part2maxlength]="2"
                [part3maxlength]="4" [part1placeholder]="'xxx'" [part2placeholder]="'xx'" [part3placeholder]="'xxxx'"
                (errorMessage)="addVerifySSNErrorMessage($event)"></chexsystem-ws-phonecontrol>
              <mat-error class="mat-body-1" *ngIf="
                  !ssnnomatch &&
                  !verifyssnminlength &&
                  !verifyssninvalid &&
                  firstFormGroup.controls['verifyssnCtrl']?.errors?.required
                ">{{ 'REGISTRATION.VERIFY U.S.SOCIAL SECURITY NUMBER ERR' | translate }}</mat-error>
              <mat-error class="mat-body-1" *ngIf="verifyssnminlength">{{ 'REGISTRATION.VERIFY U.S.SOCIAL SECURITY
                NUMBER MINLENGTH ERR' | translate }}</mat-error>
              <mat-error class="mat-body-1" *ngIf="verifyssninvalid">{{ 'REGISTRATION.VERIFY U.S.SOCIAL SECURITY NUMBER
                INVALID ERR' | translate }}</mat-error>
              <mat-error class="mat-body-1" *ngIf="ssnnomatch && !verifyssninvalid && !verifyssnminlength">{{
                'REGISTRATION.U.S.SOCIAL SECURITY NUMBER MISMATCH ERR' | translate }}</mat-error>
            </mat-form-field>
            <span class="eyeIcon">
              <mat-icon [matTooltip]="eyeIconTooltipVerifySSN" #maskEyeVerifySSN
                [fisIcon]="eyeIconTypeVerifySSN" (click)="handleSSNMasking()" rufId fisStyle
                fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid"></mat-icon>
            </span>
          </div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
          <div role="region" aria-label="toolbartitle" class="ruf-caption-2 chex-ruf-caption">{{ 'REGISTRATION.TOOLBAR
            TITLE' | translate }}</div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="currentaddressline1" class="chex-form-field-label">{{
              'REGISTRATION.CURRENT ADDRESS LINE 1' | translate }}</mat-label>
            <input id="addressCtrl" minlength="2" maxlength="50" formControlName="address1Ctrl" matInput
              placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
            <mat-error *ngIf="firstFormGroup.get('address1Ctrl')?.hasError('required')">{{ 'REGISTRATION.CURRENT
              ADDRESS LINE 1 ERR' | translate }}</mat-error>
            <mat-error *ngIf="firstFormGroup.get('address1Ctrl')?.errors?.pattern"
              matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
              matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Current Address Line 1 is invalid. Hover for details.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="currentaddressline2" class="chex-form-field-label">{{
              'REGISTRATION.CURRENT ADDRESS LINE 2' | translate }}</mat-label>
            <input minlength="2" maxlength="50" formControlName="address2Ctrl" matInput placeholder="Enter Address"
              pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
            <mat-error *ngIf="firstFormGroup.get('address2Ctrl')?.errors?.pattern"
              matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
              matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Current Address Line 2 is invalid. Hover for
              details.</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="large">
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="city" class="chex-form-field-label">{{ 'REGISTRATION.CITY' |
              translate }}</mat-label>
            <input id="City" minlength="2" maxlength="30" formControlName="cityCtrl" matInput placeholder="Enter City"
              (keypress)="keyPressAlphabets($event)" pattern="^[a-zA-Z ]{2,30}$" autocompleteOff />
            <mat-error *ngIf="firstFormGroup.get('cityCtrl')?.hasError('required')">{{ 'REGISTRATION.CITY ERR' |
              translate }}</mat-error>
            <mat-error *ngIf="firstFormGroup.get('cityCtrl')?.errors?.pattern"
              matTooltip="{{ invalidErrorMessage.city }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
              matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>City is invalid. Hover for details.</mat-error>
          </mat-form-field>
          <mat-form-field class="chex-suffix_dropdown" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="state1" class="chex-form-field-label">{{ 'REGISTRATION.STATE1' |
              translate }}</mat-label>
            <mat-select fisStyle placeholder="Select State" formControlName="stateCtrl">
              <mat-option *ngFor="let state of states" [value]="state.code">
                {{ state.code }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="firstFormGroup.get('stateCtrl')?.hasError('required')">{{ 'REGISTRATION.STATE ERR' |
              translate }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="16px" rufMarginBottom="medium">
          <mat-form-field class="chex-form-field" boxStyle floatLabel="always">
            <mat-label role="region" aria-label="zipcode" class="chex-form-field-label">{{ 'REGISTRATION.ZIP CODE' |
              translate }}</mat-label>
            <input id="zipCode" minlength="5" maxlength="5" formControlName="postalCodeCtrl" matInput size="5"
              placeholder="Enter Zip Code" (keypress)="keyPressNumbers($event)" pattern="^[0-9]{5}$" autocompleteOff />
            <mat-error *ngIf="
                  firstFormGroup.get('postalCodeCtrl')?.hasError('required')
                ">{{ 'REGISTRATION.ZIP CODE ERR' | translate }}</mat-error>
            <mat-error *ngIf="firstFormGroup.get('postalCodeCtrl')?.errors?.pattern"
              matTooltip="{{ invalidErrorMessage.zipCode }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
              matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Zip Code is invalid. Hover for details.</mat-error>
            <mat-error *ngIf="firstFormGroup.controls['postalCodeCtrl'].errors?.isInValidRange">
              {{invalidErrorMessage.stateZipCodeRange}}</mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-step>

     <mat-step [stepControl]="secondFormGroup">
      <div class="chex-quiz-container">
        <div class="chex-quiz-block">
          <form [formGroup]="secondFormGroup" [formGroup]="secondFormGroup" (ngSubmit)="onIdaQuizSubmit($event)"
            autocomplete="off">
            <ng-template matStepLabel>
              <span>{{ ida_quiz_title }}</span><br />
              <span>{{ ida_quiz_subtitle }}</span>
            </ng-template>
            <div *ngFor="let question of idaQuizSection.idaQuestion; index as i">
              <mat-label class="chex-font">{{ question.questionText
                }}<span class="mat-required-none"> *</span></mat-label>
              <mat-radio-group class="chex-quiz-radio-list" [id]="question.id" [formControlName]="question.id">
                <mat-radio-button *ngFor="let option of question.options" [value]="option.value">
                  {{ option.text }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </form>
        </div>
        <div *ngFor="let info of timersInfo; let indexOfElement = index">
          <quiz-timer [info]="info" (timerEnd)="quizEnd($event)"></quiz-timer>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <div role="region" aria-label="fieldtext" class="mat-body-2 chex-title chex-font">
        {{ 'REGISTRATION.MANDATORY FIELDS TEXT' | translate }}
      </div>
      <form [formGroup]="thirdFormGroup" class="step3" id="thirdFormGroup" autocomplete="off">
        <ng-template matStepLabel>{{ 'REGISTRATION.HEADER LABEL 3' | translate }}</ng-template>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
          <div class="help-container chex-screen3-form-field">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="username" class="chex-form-field-label">{{ 'REGISTRATION.USERNAME' |
                translate }}</mat-label>
              <input id="userName" minlength="8" maxlength="25" formControlName="userNameCtrl" fisStyle matInput
                placeholder="Enter Username" [(ngModel)]="userName" (blur)="checkforUserNameAvailability($event)"
                pattern="^[A-za-z0-9_.@#$%+/^'-]{8,25}$" autocompleteOff />
              <mat-error *ngIf="thirdFormGroup.get('userNameCtrl')?.hasError('required')">Username is required.
              </mat-error>
              <mat-error *ngIf="
                  thirdFormGroup
                    .get('userNameCtrl')
                    ?.hasError('usernamenotavailable')
                ">Username already exists.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.get('userNameCtrl')?.errors?.pattern ||
                  thirdFormGroup.controls['userNameCtrl']?.errors?.isInValid ||
                  thirdFormGroup.controls['userNameCtrl']?.errors?.name ===
                    'email'
                " matTooltip="{{ invalidErrorMessage.userName }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Invalid UserName. Hover for details.</mat-error>
            </mat-form-field>
            <button type="button" role="region" aria-label="info" mat-icon-button class="chex-popover-button-style"
              [rufTrigger]="dropdownPanelusername" #triggerfirstname="rufTrigger" fisStyle tabindex="-1">
              <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
            </button>
            <ruf-dropdown-panel class="" [hasBackdrop]="hasBackdrop" #dropdownPanelusername rufId
              [origin]="triggerfirstname" [type]="panel.type" xPosition="center" yPosition="bottom">
              <div role="region" aria-label="characters" class="chex-popover-content mat-body-1">
                The following rules apply for Username: <br />
                The Username must contain the following: <br />
                8 - 25 characters including special characters A-Z 0-9 _ . @ - # $ % + / ^ and '<br />
                The Username must not be an email address.
              </div>
            </ruf-dropdown-panel>
          </div>
          <div class="help-container chex-screen3-form-field">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="password" class="chex-form-field-label">{{ 'REGISTRATION.PASSWORD' |
                translate }}</mat-label>
              <input id="Password" [type]="currentPasswordType" formControlName="pwdCtrl" fisStyle matInput
                placeholder="Enter Password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+])(?!.*\s).+$"                                                      
                minlength="12" maxlength="32" autocompleteOff />
              <mat-error *ngIf="thirdFormGroup.get('pwdCtrl')?.hasError('required')">Password is required.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.get('pwdCtrl')?.errors?.pattern ||
                  thirdFormGroup.get('pwdCtrl')?.errors?.minlength ||
                  thirdFormGroup.get('pwdCtrl')?.errors?.maxlength ||
                  thirdFormGroup.controls['pwdCtrl']?.errors?.isInValid
                " matTooltip="{{ invalidErrorMessage.passwordText }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Invalid Password. Hover for details.</mat-error>
            </mat-form-field>
            <button type="button" role="region" aria-label="info1" mat-icon-button class="chex-popover-button-style"
              [rufTrigger]="dropdownPanelpassword" #triggerpassword="rufTrigger" fisStyle tabindex="-1">
              <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
            </button>
            <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelpassword rufId [origin]="triggerpassword"
              [type]="panel.type" xPosition="center" yPosition="bottom">
              <div role="region" aria-label="rules" class="chex-popover-content mat-body-1">
                The following rules apply for Password and Verify Password:
                <br />
                The Password must contain the following: <br />
                12 – 32 characters including uppercase letters, lowercase letters, numbers,
                special characters.<br />
                (Special characters include one of the following ! @ # $ % ^ & +) <br />
                The Password cannot contain your username, in forward or
                reverse order. <br />
                The Password cannot contain your first, middle or last name, in
                forward or reverse order.
              </div>
            </ruf-dropdown-panel>
          </div>
          <div class="help-container chex-screen3-form-field">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="verifypswd" class="chex-form-field-label">{{ 'REGISTRATION.VERIFY
                PASSWORD' | translate }}</mat-label>
              <input id="verifyPassword" [type]="currentPasswordType" formControlName="verifypwdCtrl" fisStyle matInput
                placeholder="Enter Verify Password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&+])(?!.*\s).+$" minlength="12" maxlength="32"
                autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('verifypwdCtrl')?.hasError('required')
                ">Verify Password is required.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.controls['verifypwdCtrl'].errors?.mustMatch
                ">Password does not match.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.get('verifypwdCtrl')?.errors?.pattern ||
                  thirdFormGroup.get('verifypwdCtrl')?.errors?.minlength ||
                  thirdFormGroup.get('verifypwdCtrl')?.errors?.maxlength ||
                  thirdFormGroup.controls['verifypwdCtrl']?.errors?.isInValid
                " matTooltip="{{ invalidErrorMessage.passwordText }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Invalid Password. Hover for details.</mat-error>
            </mat-form-field>
            <span id="currentPwdMasking" class="eyeIcon">
              <mat-icon [matTooltip]="currentPasswordEyeIconTooltip" [fisIcon]="currentPasswordEyeIconType" (click)="handleCurrentMasking('password')"  rufId fisStyle fontSet="fisfont-solid" class="fis-icon-accounts ruf-solid" style="font-size: 20px;"></mat-icon>
            </span>
          </div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="emailaddr" class="chex-form-field-label">{{ 'REGISTRATION.EMAIL
                ADDRESS' | translate }}</mat-label>
              <input id="email" minlength="4" maxlength="50" formControlName="emailCtrl" fisStyle matInput (blur)="checkforEmailAddressAvailability($event)"
                placeholder="Enter Email Address" pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$' autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.controls['emailCtrl']?.hasError('required')
                ">Email Address is required.</mat-error>
                <mat-error *ngIf="
                  thirdFormGroup
                    .get('emailCtrl')
                    ?.hasError('emailAddressAvailable')
                ">Email is already in use.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.get('emailCtrl')?.errors?.pattern ||
                  thirdFormGroup.get('emailCtrl')?.errors?.name === 'pattern'
                " matTooltip="{{ invalidErrorMessage.email }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Email Address is invalid. Hover for details.</mat-error>
            </mat-form-field>
            <div class="chex-form-ssn-field">
              <mat-form-field class="chex-form-field" floatLabel="always" boxStyle>
                <mat-label role="region" aria-label="phnnumber" class="chex-form-field-label">{{ 'REGISTRATION.PHONE
                  NUMBER' | translate }}</mat-label>
                <chexsystem-ws-phonenumbercontrol id="cellphoneCtrl1" #cellphoneCtrl1 [inputtype]="'text'"
                  [part1maxlength]="3" [part2maxlength]="3" [part3maxlength]="4" [part1placeholder]="'xxx'"
                  [part2placeholder]="'xxx'" [part3placeholder]="'xxxx'" formControlName="cellphoneCtrl"
                  (errorMessage)="addPhoneNumberErrorMessage($event)" required boxStyle>
                </chexsystem-ws-phonenumbercontrol>
                <mat-error class="mat-body-1" *ngIf="
                    !phonenominlength && !phonenumberinvalid && 
                    thirdFormGroup.controls['cellphoneCtrl']?.errors?.required
                  ">Mobile/Phone Number is required.</mat-error>
                <mat-error class="mat-body-1" *ngIf="phonenominlength">Mobile/Phone Number is invalid.  10 digit numeric
                  value must
                  be given.</mat-error>
                <mat-error class="mat-body-1" *ngIf="phonenumberinvalid">Mobile/Phone Number is invalid.</mat-error>
              </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px"  rufMarginBottom="medium">
          <div class="help-container chex-screen3-form-field">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="dlnumber" class="chex-form-field-label">{{ 'REGISTRATION.DL NUMBER' |
                translate }}</mat-label>
              <input id="DLNumber" formControlName="DLNumberCtrl" fisStyle matInput
                placeholder="Enter DL, State or Military ID Number" (blur)="loadDLValidation()"
                autocompleteOff dlMasking/>
              <mat-error *ngIf="
                  thirdFormGroup.controls['DLNumberCtrl'].errors?.DLNumberEnter
                ">Please enter Drivers License, State or Military ID
                Number.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.controls['DLNumberCtrl'].errors
                    ?.patterndoesnotmatch
                " matTooltip="{{ invalidErrorMessage.driverLicense }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Invalid Drivers License, State or Military ID
                Number.</mat-error>
            </mat-form-field>
            <button type="button" mat-icon-button class="chex-popover-button-style"
              [rufTrigger]="dropdownPanelDriversLicense" #triggerDriversLicense="rufTrigger" fisStyle tabindex="-1">
              <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
            </button>
            <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelDriversLicense rufId
              [origin]="triggerDriversLicense" [type]="panel.type" xPosition="center" yPosition="bottom">
              <div role="region" aria-label="idnumber" class="mat-body-1 chex-popover-content">
                Please provide your state-issued ID number. If providing a
                military ID number, select “MY” as the state of issuance.
              </div>
            </ruf-dropdown-panel>
          </div>
            <mat-form-field class="chex-suffix_dropdown" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="stateofissurance" class="chex-form-field-label">{{
                'REGISTRATION.STATE OF ISSUANCE' | translate }}</mat-label>
              <mat-select fisStyle placeholder="State of Issuance" formControlName="DLstateCtrl"
                (selectionChange)="loadDLValidation()">
                <mat-option role="region" aria-label="state2" value="">{{ 'REGISTRATION.STATE2' | translate }}
                </mat-option>
                <mat-option *ngFor="let state of dlstates" [value]="state.code">
                  {{ state.code }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  thirdFormGroup.controls['DLstateCtrl'].errors?.stateselect
                ">Please select state.</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="none">
          <div role="region" aria-label="correspondencedelivery" class="ruf-caption-2 chex-ruf-caption">
            {{ 'REGISTRATION.CORRESPONDENCE DELIVERY PREFERENCE' | translate }} *
            <button type="button" mat-icon-button [rufTrigger]="dropdownPanelCorrespondence"
              #triggerCorrespondence="rufTrigger" fisStyle tabindex="-1">
              <mat-icon rufId fisIcon="information" class="fis-icon-xs"></mat-icon>
            </button>
            <ruf-dropdown-panel [hasBackdrop]="hasBackdrop" #dropdownPanelCorrespondence rufId
              [origin]="triggerCorrespondence" [type]="panel.type" xPosition="center" yPosition="bottom">
              <div role="region" aria-label="correspondenceportal" class="mat-body-1 chex-popover-content">
                How would you like to receive documents from ChexSystems?<br />
                When 'portal' is selected, all future correspondence from ChexSystems will be delivered to the
                portal,<br />
                and you will receive an email when a document becomes available online.
              </div>
            </ruf-dropdown-panel>
          </div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" class="correspondence" rufMarginBottom="medium">
          <mat-radio-group themeColorFor="mat-radio" fisStyle name="more_options" rufId="basic_example_radio_group"
            formControlName="deliverypreference" [(ngModel)]="defaultDeliverypreference" ngDefaultControl>
            <mat-radio-button fisStyle *ngFor="let option of deliverypreference" name="more_options" [value]="option">
              {{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large"
          class="chex-ruf-caption-heading">
          <div role="region" aria-label="previousaddrlabel1" class="ruf-caption-2 chex-ruf-caption">{{
            'REGISTRATION.PREVIOUS ADDRESS LABEL 1' | translate }}</div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="previousaddrline1" class="chex-form-field-label">{{
                'REGISTRATION.PREVIOUS ADDRESS LINE 1' | translate }}</mat-label>
              <input id="preAddLine11" minlength="2" maxlength="50" formControlName="prevaddress11Ctrl" matInput
                placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevaddress11Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.addressLine1Required }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevaddress11Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Address Line 1 is invalid. Hover for details.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="previousaddrline2" class="chex-form-field-label">{{
                'REGISTRATION.PREVIOUS ADDRESS LINE 2' | translate }}</mat-label>
              <input id="preAddLine2" minlength="2" maxlength="50" formControlName="prevaddress12Ctrl" matInput
                placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevaddress12Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.addressLine2Required }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevaddress12Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Address Line 2 is invalid. Hover for details.
              </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="city" class="chex-form-field-label">{{ 'REGISTRATION.CITY' |
                translate }}</mat-label>
              <input id="prevCity" minlength="2" maxlength="30" formControlName="prevcity1Ctrl" matInput
                placeholder="Enter City" (keypress)="keyPressAlphabets($event)" pattern="^[a-zA-Z ]{2,30}$"
                autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevcity1Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.cityRequired }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevcity1Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.city }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
                matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>City is invalid. Hover for details.</mat-error>
            </mat-form-field>
            <mat-form-field class="chex-suffix_dropdown" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="state1" class="chex-form-field-label">{{ 'REGISTRATION.STATE1' |
                translate }}</mat-label>
              <mat-select fisStyle placeholder="Select State" formControlName="prevstate1Ctrl">
                <mat-option role="region" aria-label="state2" value="">{{ 'REGISTRATION.STATE2' | translate }}
                </mat-option>
                <mat-option *ngFor="let state of states" [value]="state.code">
                  {{ state.code }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  thirdFormGroup.get('prevstate1Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.stateRequired }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px"  rufMarginBottom="medium">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="zipcode" class="chex-form-field-label">{{ 'REGISTRATION.ZIP CODE' |
                translate }}</mat-label>
              <input id="prevZipCode" minlength="5" maxlength="5" formControlName="prevZipCode1Ctrl" matInput size="5"
                placeholder="Enter Zip Code" (keypress)="keyPressNumbers($event)" pattern="^[0-9]{5}$"
                autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevZipCode1Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.zipCodeRequired }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevZipCode1Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.zipCode }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Zip Code is invalid. Hover for details.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.controls['prevZipCode1Ctrl'].errors
                    ?.isInValidRange
                ">{{ invalidErrorMessage.stateZipCodeRange }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px"  rufMarginBottom="large">
          <div role="region" aria-label="previousaddrlabel2" class="ruf-caption-2 chex-ruf-caption">{{
            'REGISTRATION.PREVIOUS ADDRESS LABEL 2' | translate }}</div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="previousaddrline1" class="chex-form-field-label">{{
                'REGISTRATION.PREVIOUS ADDRESS LINE 1' | translate }}</mat-label>
              <input minlength="2" maxlength="50" formControlName="prevaddress21Ctrl" matInput
                placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevaddress21Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.addressLine1Required }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevaddress21Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Address Line 1 is invalid. Hover for details.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="previousaddrline2" class="chex-form-field-label">{{
                'REGISTRATION.PREVIOUS ADDRESS LINE 2' | translate }}</mat-label>
              <input minlength="2" maxlength="50" formControlName="prevaddress22Ctrl" matInput
                placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevaddress22Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.addressLine2Required }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevaddress22Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Address Line 2 is invalid. Hover for details.
              </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="city" class="chex-form-field-label">{{ 'REGISTRATION.CITY' |
                translate }}</mat-label>
              <input minlength="2" maxlength="30" formControlName="prevcity2Ctrl" matInput placeholder="Enter City"
                (keypress)="keyPressAlphabets($event)" pattern="^[a-zA-Z ]{2,30}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevcity2Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.cityRequired }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevcity2Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.city }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
                matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>City is invalid. Hover for details.</mat-error>
            </mat-form-field>
            <mat-form-field class="chex-suffix_dropdown" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="state1" class="chex-form-field-label">{{ 'REGISTRATION.STATE1' |
                translate }}</mat-label>
              <mat-select fisStyle placeholder="Select State" formControlName="prevstate2Ctrl">
                <mat-option role="region" aria-label="state2" value="">{{ 'REGISTRATION.STATE2' | translate }}
                </mat-option>
                <mat-option *ngFor="let state of states" [value]="state.code">
                  {{ state.code }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  thirdFormGroup.get('prevstate2Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.stateRequired }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="medium">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="zipcode" class="chex-form-field-label">{{ 'REGISTRATION.ZIP CODE' |
                translate }}</mat-label>
              <input minlength="5" maxlength="5" formControlName="prevZipCode2Ctrl" matInput size="5"
                placeholder="Enter Zip Code" (keypress)="keyPressNumbers($event)" pattern="^[0-9]{5}$"
                autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevZipCode2Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.zipCodeRequired }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevZipCode2Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.zipCode }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Zip Code is invalid. Hover for details.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.controls['prevZipCode2Ctrl'].errors
                    ?.isInValidRange
                ">{{ invalidErrorMessage.stateZipCodeRange }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px"  rufMarginBottom="large">
          <div role="region" aria-label="previousaddrlabel3" class="ruf-caption-2 chex-ruf-caption">{{
            'REGISTRATION.PREVIOUS ADDRESS LABEL 3' | translate }}</div>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="previousaddrline1" class="chex-form-field-label">{{
                'REGISTRATION.PREVIOUS ADDRESS LINE 1' | translate }}</mat-label>
              <input minlength="2" maxlength="50" formControlName="prevaddress31Ctrl" matInput
                placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevaddress31Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.addressLine1Required }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevaddress31Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Address Line 1 is invalid. Hover for details.
              </mat-error>
            </mat-form-field>
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="previousaddrline2" class="chex-form-field-label">{{
                'REGISTRATION.PREVIOUS ADDRESS LINE 2' | translate }}</mat-label>
              <input minlength="2" maxlength="50" formControlName="prevaddress32Ctrl" matInput
                placeholder="Enter Address" pattern="^[a-zA-Z0-9 ]{2,50}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevaddress32Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.addressLine2Required }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevaddress32Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.address }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Previous Address Line 2 is invalid. Hover for details.
              </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="city" class="chex-form-field-label">{{ 'REGISTRATION.CITY' |
                translate }}</mat-label>
              <input minlength="2" maxlength="30" formControlName="prevcity3Ctrl" matInput placeholder="Enter City"
                (keypress)="keyPressAlphabets($event)" pattern="^[a-zA-Z ]{2,30}$" autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevcity3Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.cityRequired }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevcity3Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.city }}" matTooltipPosition="below" [matTooltipDisabled]="disabled"
                matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>City is invalid. Hover for details.</mat-error>
            </mat-form-field>
            <mat-form-field class="chex-suffix_dropdown" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="state1" class="chex-form-field-label">{{ 'REGISTRATION.STATE1' |
                translate }}</mat-label>
              <mat-select fisStyle placeholder="Select State" formControlName="prevstate3Ctrl">
                <mat-option role="region" aria-label="state2" value="">{{ 'REGISTRATION.STATE2' | translate }}
                </mat-option>
                <mat-option *ngFor="let state of states" [value]="state.code">
                  {{ state.code }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  thirdFormGroup.get('prevstate3Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.stateRequired }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px"  rufMarginBottom="large">
            <mat-form-field class="chex-screen3-form-field" boxStyle floatLabel="always">
              <mat-label role="region" aria-label="zipcode" class="chex-form-field-label">{{ 'REGISTRATION.ZIP CODE' |
                translate }}</mat-label>
              <input minlength="5" maxlength="5" formControlName="prevZipCode3Ctrl" matInput size="5"
                placeholder="Enter Zip Code" (keypress)="keyPressNumbers($event)" pattern="^[0-9]{5}$"
                autocompleteOff />
              <mat-error *ngIf="
                  thirdFormGroup.get('prevZipCode3Ctrl')?.errors?.name ===
                  'required'
                ">{{ invalidErrorMessage.zipCodeRequired }}</mat-error>
              <mat-error *ngIf="thirdFormGroup.get('prevZipCode3Ctrl')?.errors?.pattern"
                matTooltip="{{ invalidErrorMessage.zipCode }}" matTooltipPosition="below"
                [matTooltipDisabled]="disabled" matTooltipShowDelay="0" matTooltipHideDelay="0" [matTooltipClass]="{
                  'demo-red-tooltip': showExtraClass,
                  'fis-style': false
                }" fisStyle>Zip Code is invalid. Hover for details.</mat-error>
              <mat-error *ngIf="
                  thirdFormGroup.controls['prevZipCode3Ctrl'].errors
                    ?.isInValidRange
                ">{{ invalidErrorMessage.stateZipCodeRange }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" rufMarginVertical  rufMarginBottom="medium">
            <mat-checkbox matInput fisStyle formControlName="termsnconditionsCheckCtrl" [(ngModel)]="termsnconditions"
              ngDefaultControl>
              {{ 'REGISTRATION.TERMS AND CONDITION1' | translate }}
              <a href (click)="navigateToTermsandConditions($event)">
                {{ 'REGISTRATION.TERMS AND CONDITION2' | translate }}</a>.
            </mat-checkbox>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>

  <ng-template rufStepperFooter class="ruf-stepper-footer ruf-default chex-bottom-text">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical>
      <div>
        <button mat-raised-button fisStyle id="submitBtn" color="primary" class="chex-form-button" rufId ruf-stepper-actions
          type="submit" [disabled]="!termsnconditions ? 'disabled' : null" (click)="submitClick(rufStepper)"
          [attr.form]="userRegistrationForm">
          {{ nextbuttontext }}
        </button>
        <button role="region" id="cancelBtn" aria-label="cancel" mat-stroked-button fisStyle color="primary" class="chex-form-button"
          rufId ruf-stepper-actions type="button" (click)="cancelClick(rufStepper)">
          {{ 'BUTTONS.CANCEL' | translate }}
        </button>
      </div>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="27px" rufMarginVertical>
      <div>
        <div role="region" aria-label="haveanacc1" class="mat-body-2" *ngIf="stepData?.isFirst">
          {{ 'REGISTRATION.HAVEANACCOUNT1' | translate }} <a href class="chex-font"
            (click)="navigateToLogin($event)">&ensp;{{ 'REGISTRATION.HAVEANACCOUNT2' | translate }}</a>
        </div>
      </div>
    </div>
  </ng-template>
</ruf-stepper>
