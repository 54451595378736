import { Guid } from "guid-typescript";

export namespace disputes{
  export class disputeData{
    consumerid: string;
    name: string = "";
    personalInfo: personalInformation = new personalInformation();
    reportedInfo: reportedInformation = new reportedInformation();
    inquiriesVBO: inquiriesViewedByOthers = new inquiriesViewedByOthers();
    checkCashingInq: checkCashingInquiries = new checkCashingInquiries();
    retailInfo: retailInformation = new retailInformation();
    historyofCO: historyofChecksOrdered = new historyofChecksOrdered();
    inquiriesVBY: inquiriesViewedByOthers = new inquiriesViewedByOthers();
}

export class DisputeName{
  DisputeDataHolder: DisputeDataHolder;
  uniqueID:Guid = Guid.create();
  name: string = "";
  isSectionDisputed: boolean = false;
  isSectionDisputable: boolean = false;
  SectionButtonText: string = "";
}

export class DisputeAddress{
  DisputeDataHolder: DisputeDataHolder;
  uniqueID:Guid = Guid.create();
  address: string = "";
  isSectionDisputed: boolean = false;
  isSectionDisputable: boolean = false;
  SectionButtonText: string = "";
}
export class DisputePhone{
  DisputeDataHolder: DisputeDataHolder;
  uniqueID:Guid = Guid.create();
  phoneNumber: string = "";
  phntype: string = "";
  isSectionDisputed: boolean = false;
  isSectionDisputable: boolean = false;
  SectionButtonText: string = "";
}

export class DisputeEmailAddress{
  DisputeDataHolder: DisputeDataHolder;
  uniqueID:Guid = Guid.create();
  emailAddress: string = "";
  isSectionDisputed: boolean = false;
  isSectionDisputable: boolean = false;
  SectionButtonText: string = "";
}
export class personalInformation{
    DisputeDataHolder: DisputeDataHolder;
    uniqueID:Guid = Guid.create();
    // name: string = "";
    // nameIsDisputable: boolean = false;
    // nameIsDisputed: boolean = false;
    // nameButtonText: string = "";

    // address: string = "";
    // addressIsDisputable: boolean = false;
    // addressIsDisputed: boolean = false;
    // addressButtonText: string = "";

    // phoneNumber: string = "";
    // phoneNumberIsDisputable: boolean = false;
    // phoneNumberIsDisputed: boolean = false;
    // phoneNumberButtonText: string = "";

    // emailAddress: string = "";
    // emailAddressIsDisputable: boolean = false;
    // emailAddressIsDisputed: boolean = false;
    // emailAddressButtonText: string = "";

    DisputeName : DisputeName[];
    DisputeAddress: DisputeAddress[];
    DisputePhone: DisputePhone[];
    DisputeEmailAddress: DisputeEmailAddress[];

    dateOfBirth: Date;
    dateOfBirthIsDisputable: boolean = false;
    dateOfBirthIsDisputed: boolean = false;
    dateOfBirthButtonText: string = "";
}

export class reportedInformation{
    reportedInfoItemList: reportedInformationItem[] = [];
}

export class reportedInformationItem{
    uniqueID:Guid = Guid.create();
    DisputeDataHolder: DisputeDataHolder;
    sourceOfInformation: string = "";
    sourceOfInformationIsDisputable: boolean = false;
    sourceOfInformationIsDisputed: boolean = false;
    sourceOfInformationButtonText: string = "";

    phoneNumber: string = "";
    phoneNumberIsDisputable: boolean = false;
    phoneNumberIsDisputed: boolean = false;
    phoneNumberButtonText: string = "";

    reportedFor: string = "";
    reportedForIsDisputable: boolean = false;
    reportedForIsDisputed: boolean = false;
    reportedForButtonText: string = "";

    reportedName: string = "";
    reportedNameIsDisputable: boolean = false;
    reportedNameIsDisputed: boolean = false;
    reportedNameButtonText: string = "";

    reportedAddress: string = "";
    reportedAddressIsDisputable: boolean = false;
    reportedAddressIsDisputed: boolean = false;
    reportedAddressButtonText: string = "";

    reportedID: string = "";
    reportedIDIsDisputable: boolean = false;
    reportedIDIsDisputed: boolean = false;
    reportedIDButtonText: string = "";

    driverLicense: string = "";
    driverLicenseIsDisputable: boolean = false;
    driverLicenseIsDisputed: boolean = false;
    driverLicenseButtonText: string = "";

    state: string = "";
    stateIsDisputable: boolean = false;
    stateIsDisputed: boolean = false;
    stateButtonText: string = "";

    dateofClosure: string="";
    dateofClosureIsDisputable: boolean = false;
    dateofClosureIsDisputed: boolean = false;
    dateofClosureButtonText: string = "";

    rtn: string = "";
    rtnIsDisputable: boolean = false;
    rtnIsDisputed: boolean = false;
    rtnButtonText: string = "";

    accountNumber: string = "";
    accountNumberIsDisputable: boolean = false;
    accountNumberIsDisputed: boolean = false;
    accountNumberButtonText: string = "";

    originalChargeOffAmount: number = 0;
    originalChargeOffAmountIsDisputable: boolean = false;
    originalChargeOffAmountIsDisputed: boolean = false;
    originalChargeOffAmountButtonText: string = "";

    closureStatus: string = "";
    closureStatusIsDisputable: boolean = false;
    closureStatusIsDisputed: boolean = false;
    closureStatusButtonText: string = "";

    closureStatusDate: string="";
    closureStatusDateIsDisputable: boolean = false;
    closureStatusDateIsDisputed: boolean = false;
    closureStatusDateButtonText: string = "";

    isSectionDisputed: boolean = false;
    isSectionDisputable: boolean = false;
    SectionButtonText: string = "";
}

export class inquiriesViewedByOthers{
    inquiriesViewedByOthersItemList: inquiriesViewedByOthersItem[] = [];
}

export class inquiriesViewedByOthersItem{
    uniqueID:Guid = Guid.create();
    DisputeDataHolder: DisputeDataHolder;
    inquirer: string = "";
    inquirerIsDisputable: boolean = false;
    inquirerIsDisputed: boolean = false;
    inquirerButtonText: string = "";
    inquirerName: string = "";

    iPhoneNumber: string = "";
    iPhoneNumberIsDisputable: boolean = false;
    iPhoneNumberIsDisputed: boolean = false;
    iPhoneNumberButtonText: string = "";

    onBehalfOf: string = "";
    onBehalfOfIsDisputable: boolean = false;
    onBehalfOfIsDisputed: boolean = false;
    onBehalfOfButtonText: string = "";

    oPhoneNumber: string = "";
    oPhoneNumberIsDisputable: boolean = false;
    oPhoneNumberIsDisputed: boolean = false;
    oPhoneNumberButtonText: string = "";
   

    inquiryDate: Date;
    inquiryDateIsDisputable: boolean = false;
    inquiryDateIsDisputed: boolean = false;
    inquiryDateButtonText: string = "";

    name: string = "";
    nameIsDisputable: boolean = false;
    nameIsDisputed: boolean = false;
    nameButtonText: string = "";

    address: string = "";
    addressIsDisputable: boolean = false;
    addressIsDisputed: boolean = false;
    addressButtonText: string = "";

    ssnID: string = "";
    ssnIDIsDisputable: boolean = false;
    ssnIDIsDisputed: boolean = false;
    ssnIDButtonText: string = "";

    driverLicense: string = "";
    driverLicenseIsDisputable: boolean = false;
    driverLicenseIsDisputed: boolean = false;
    driverLicenseButtonText: string = "";

    state: string = "";
    stateIsDisputable: boolean = false;
    stateIsDisputed: boolean = false;
    stateButtonText: string = "";

    dateofClosure: Date;
    dateofClosureIsDisputable: boolean = false;
    dateofClosureIsDisputed: boolean = false;
    dateofClosureButtonText: string = "";

    phoneNumbers: string = "";
    phoneNumbersIsDisputable: boolean = false;
    phoneNumbersIsDisputed: boolean = false;
    phoneNumbersButtonText: string = "";

    email: string = "";
    emailIsDisputable: boolean = false;
    emailIsDisputed: boolean = false;
    emailButtonText: string = "";

    isSectionDisputed: boolean = false;
    isSectionDisputable: boolean = false;
    SectionButtonText: string = "";
}

export class checkCashingInquiries{
    checkCashingInquiriesItemList: checkCashingInquiriesItem[] = [];
}
export class checkCashingInquiriesItem{
    uniqueID:Guid = Guid.create();
    DisputeDataHolder: DisputeDataHolder;
    inquirer: string = "";
    inquirerIsDisputable: boolean = false;
    inquirerIsDisputed: boolean = false;
    inquirerButtonText: string = "";

    inquiryDate: Date;
    inquiryDateIsDisputable: boolean = false;
    inquiryDateIsDisputed: boolean = false;
    inquiryDateButtonText: string = "";

    inquiryDollarAmount: number = 0;
    inquiryDollarAmountIsDisputable: boolean = false;
    inquiryDollarAmountIsDisputed: boolean = false;
    inquiryDollarAmountButtonText: string = "";
    
    ssnID: string = "";
    ssnIDIsDisputable: boolean = false;
    ssnIDIsDisputed: boolean = false;
    ssnIDButtonText: string = "";

    driverLicense: string = "";
    driverLicenseIsDisputable: boolean = false;
    driverLicenseIsDisputed: boolean = false;
    driverLicenseButtonText: string = "";

    state: string = "";
    stateIsDisputable: boolean = false;
    stateIsDisputed: boolean = false;
    stateButtonText: string = "";

    isSectionDisputed: boolean = false;
    isSectionDisputable: boolean = false;
    SectionButtonText: string = "";
}

export class retailInformation{
    retailInformatioItemList: retailInformationItem[] = [];
}

export class retailInformationItem{
    uniqueID:Guid = Guid.create();
    DisputeDataHolder: DisputeDataHolder;
    payee: string = "";
    payeeIsDisputable: boolean = false;
    payeeIsDisputed: boolean = false;
    payeeButtonText: string = "";

    amountOfCheck: number = 0;
    amountOfCheckIsDisputable: boolean = false;
    amountOfCheckIsDisputed: boolean = false;
    amountOfCheckButtonText: string = "";

    dateofCheck: string;
    dateofCheckIsDisputable: boolean = false;
    dateofCheckIsDisputed: boolean = false;
    dateofCheckButtonText: string = "";

    checkNumber: number = 0;
    checkNumberIsDisputable: boolean = false;
    checkNumberIsDisputed: boolean = false;
    checkNumberButtonText: string = "";
    
    dateCheckPaid: string;
    dateCheckPaidIsDisputable: boolean = false;
    dateCheckPaidIsDisputed: boolean = false;
    dateCheckPaidButtonText: string = "";

    rtn: string = "";
    rtnIsDisputable: boolean = false;
    rtnIsDisputed: boolean = false;
    rtnButtonText: string = "";

    accountNumber: string = "";
    accountNumberIsDisputable: boolean = false;
    accountNumberIsDisputed: boolean = false;
    accountNumberButtonText: string = "";

    driverLicense: string = "";
    driverLicenseIsDisputable: boolean = false;
    driverLicenseIsDisputed: boolean = false;
    driverLicenseButtonText: string = "";

    state: string = "";
    stateIsDisputable: boolean = false;
    stateIsDisputed: boolean = false;
    stateButtonText: string = "";

    isSectionDisputed: boolean = false;
    isSectionDisputable: boolean = false;
    SectionButtonText: string = "";
}

export class historyofChecksOrdered{
    historyofChecksOrderedItemList: historyofChecksOrderedItem[] = [];
}

export class historyofChecksOrderedItem{
    uniqueID:Guid = Guid.create();
    DisputeDataHolder: DisputeDataHolder;
    sourceofInformation: string = "";
    sourceofInformationIsDisputable: boolean = false;
    sourceofInformationIsDisputed: boolean = false;
    sourceofInformationButtonText: string = "";

    phoneNumber: string = "";
    phoneNumberIsDisputable: boolean = false;
    phoneNumberIsDisputed: boolean = false;
    phoneNumberButtonText: string = "";

    name: string = "";
    nameIsDisputable: boolean = false;
    nameIsDisputed: boolean = false;
    nameButtonText: string = "";

    ssnID: string = "";
    ssnIDIsDisputable: boolean = false;
    ssnIDIsDisputed: boolean = false;
    ssnIDButtonText: string = "";

    driverLicense: string = "";
    driverLicenseIsDisputable: boolean = false;
    driverLicenseIsDisputed: boolean = false;
    driverLicenseButtonText: string = "";

    state: string = "";
    stateIsDisputable: boolean = false;
    stateIsDisputed: boolean = false;
    stateButtonText: string = "";

    rtn: string = "";
    rtnIsDisputable: boolean = false;
    rtnIsDisputed: boolean = false;
    rtnButtonText: string = "";

    accountNumber: string = "";
    accountNumberIsDisputable: boolean = false;
    accountNumberIsDisputed: boolean = false;
    accountNumberButtonText: string = "";

    quantityOrdered: number = 0;
    quantityOrderedIsDisputable: boolean = false;
    quantityOrderedIsDisputed: boolean = false;
    quantityOrderedButtonText: string = "";

    dateOrderShipped: Date;
    dateOrderShippedIsDisputable: boolean = false;
    dateOrderShippedIsDisputed: boolean = false;
    dateOrderShippedButtonText: string = "";

    checkStartNumber: number = 0;
    checkStartNumberIsDisputable: boolean = false;
    checkStartNumberIsDisputed: boolean = false;
    checkStartNumberButtonText: string = "";

    isSectionDisputed: boolean = false;
    isSectionDisputable: boolean = false;
    SectionButtonText: string = "";
}

export class DisputeDiscloserSubmitData{
    uniqueID:Guid;
    type: string = "";
    reason: string = "";
    description: string = "";
    isHumanTrafficking:boolean = false;
    isIdentityTheft: boolean = false;
    data: string = '';
    DisputeDataHolder: DisputeDataHolder;
}

export class DisputeDataHolder{
  ClsrEvent: ClsrEvent;
  InqryEvent: InqryEvent;
  RtrnChkEvent: RtrnChkEvent;
  ChkOrdEvent: ChkOrdEvent;
  ChkCshEvent: ChkCshEvent;
  VarntNme: string;
  VarntAddr: VarntAddr;
  PhnVartn: PhnVartn;
  EmailVartn: EmailVartn;
  DateOfBirth: string;
}
////////////////////0-----------------@@@@@@@@@@@@@@@@@@@@@@@-----------------
export class DisputeRequestRoot {
  RqstHdr: RqstHdr
  UserName: string
}

export class RqstHdr {
  RqstTyp: string
  RqstSubTyp: string
}
/////////////////////////

export class DisputeEventsResponse {
  Code: string
  Message: string
}
export class DisputeRoot {
  DisputeEventsList: DisputeEventsList
  NmeVartn: NmeVartn
  AddVartnDtl: AddVartnDtl
  PhnVartnDtl: PhnVartnDtl
  EmailAddrVartnDtl: EmailAddrVartnDtl
  DateOfBirth: string
  Cid: string
  UserName: string
  CellPhoneNbr: string
  CorrespondenceDlvryPref: string
  NotificationEmailAddr: string
  Code: string
  Message: string
  DisputeEventsResponse: DisputeEventsResponse
}

export class DisputeEventsList {
  ClsrEventDtl: ClsrEventDtl
  InqryEventDtl: InqryEventDtl
  RtrnChkEventDtl: RtrnChkEventDtl
  ChkOrdEventDtl: ChkOrdEventDtl
  ChkCshEventDtl: ChkCshEventDtl
}

export class ClsrEventDtl {
  ClsrEvent: ClsrEvent[]
}

export class ClsrEvent {
  ClsrDtaMrtKey: string
  ClsrSsnDiscrpCd: string
  ClsrCllctnStatCd: string
  ClsrBusnCd: string
  ClsrWeight: string
  ClsrSrvcTyp: string
  ClsrPrvdrId: string
  ClsrPrvdrNme: string
  ClsrPrvdrAddr: ClsrPrvdrAddr
  ClsrPrvdrPhn: string
  ClsrMstrCustId: string
  ClsrMastCustNme: string
  ClsrCnsmrNme: ClsrCnsmrNme
  ClsrBusnTypDesc: string
  ClsrCnsmrAddr: ClsrCnsmrAddr
  ClsrGovtNbr: string
  ClsrDlNbr: string
  ClsrDlStCd: string
  ClsrBnkCd: string
  ClsrAcctNbr: string
  ClsrRptdDt: string
  ClsrRsns: ClsrRsns
  ClsrOrigChrgOffAmt: string
  ClsrStatDesc: string
  ClsrStlmtDt: string
  ClsrCllctnDtlOne: string
  ClsrCllctnDtlTwo: string
  ClsrDispTxt: string
  ClsrInvstgTypeCd: string
  ClsrRsltnTypeCd: string
  GenrcStmtInd: string
  CnsmrStmt: string
}

export class ClsrPrvdrAddr {
  ClsrPrvdrAddrLn1: string
  ClsrPrvdrCty: string
  ClsrPrvdrSt: string
  ClsrPrvdrZip: string
}

export class ClsrCnsmrNme {
  ClsrCnsmrFrstNme: string
  ClsrCnsmrMidNme: string
  ClsrCnsmrLstNme: string
  ClsrCnsmrBusnNme: string
}

export class ClsrCnsmrAddr {
  ClsrCnsmrAddrLn1: string
  ClsrCnsmrCty: string
  ClsrCnsmrSt: string
  ClsrCnsmrZip: string
}

export class ClsrRsns {
  ClsrRsn1Desc: string
  ClsrRsn2Desc: string
  ClsrRsn3Desc: string
}

export class InqryEventDtl {
  InqryEvent: InqryEvent[]
}

export class InqryEvent {
  InqDtaMrtKey: string
  InqProdPostCtgyCd: string
  InqSsnDiscrpCd: string
  InqProdCd: string
  InqBusnCd: string
  InqWeight: string
  InqSrvcTyp: string
  InqCustId: string
  InqIndstDescTxt: string
  InqNme: InqNme
  InqGovtNbr: string
  InqBnkCd: string
  InqAcctNbr: string
  InqCustNme: string
  InqCustAddr: InqCustAddr
  InqCustPhn: string
  InqDt: string
  InqMstrCustDtl: InqMstrCustDtl
  InqRslrCustDtl: InqRslrCustDtl
  InqAddr: InqAddr
  InqDlNbr: string
  InqDlStCd: string
  InqInvstgTypeCd: string
  InqRsltnTypeCd: string
  InqEvntHmePhn: string
  InqEvntWrkPhn: string
  InqEvntEmailAddr: string
}

export class InqNme {
  InqFrstNme: string
  InqMidNme: string
  InqLstNme: string
  InqBusnNme: string
}

export class InqCustAddr {
  InqCustAddrLn1: string
  InqCustCty: string
  InqCustSt: string
  InqCustZip: string
}

export class InqMstrCustDtl {
  InqMstrCustId: string
  InqMastCustNme: string
  InqMstrCustAddr: InqMstrCustAddr
  InqMstrCustPhn: string
}

export class InqMstrCustAddr {
  InqMstrCustAddrLn1: string
  InqMstrCustAddrLn2: string
  InqMstrCustCty: string
  InqMstrCustSt: string
  InqMstrCustZip: string
}

export class InqRslrCustDtl {
  InqRslrCustId: string
  InqRslrCustNme: string
  InqRslrCustAddr: InqRslrCustAddr
  InqRslrCustPhn: string
}

export class InqRslrCustAddr {
  InqRslrCustAddrLn1: string
  InqRslrCustAddrLn2: string
  InqRslrCustCty: string
  InqRslrCustSt: string
  InqRslrCustZip: string
}

export class InqAddr {
  InqAddrLn1: string
  InqCty: string
  InqSt: string
  InqZip: string
}

export class RtrnChkEventDtl {
  RtrnChkEvent: RtrnChkEvent[]
}

export class RtrnChkEvent {
  RtrnChkDtaMrtKey: string
  RtrnChkCnsmrId: string
  RtrnChkAddDt: string
  RtrnChkGovtNbr: string
  RtrnChkRsnDesc: string
  RtrnChkSuprbl: string
  RtrnChkWeight: string
  RtrnChkSrvcTyp: string
  RtrnChkDt: string
  RtrnChkNbr: string
  RtrnChkAmt: string
  RtrnChkStatDesc: string
  RtrnChkPdDt: string
  RtrnChkBnkCd: string
  RtrnChkAcctNbr: string
  RtrnChkDlNbr: string
  RtrnChkDlStCd: string
  RtrnChkPrvdrNme: string
  RtrnChkInvstgTypeCd: string
  RtrnChkRsltnTypeCd: string
}

export class ChkOrdEventDtl {
  ChkOrdEvent: ChkOrdEvent[]
}

export class ChkOrdEvent {
  ChkOrdDtaMrtKey: string
  ChkOrdCnsmrId: string
  ChkOrdDlvrAddr: ChkOrdDlvrAddr
  ChkOrdPrvdrSrcId: string
  ChkOrdPrvdrTypCd: string
  ChkOrdWeight: string
  ChkOrdSrvcTyp: string
  ChkOrdCnsmrNme: ChkOrdCnsmrNme
  ChkOrdGovtNbr: string
  ChkOrdDlNbr: string
  ChkOrdDlStCd: string
  ChkOrdBnkCd: string
  ChkOrdAcctNbr: string
  ChkOrdQtyOred: string
  ChkOrdChkStrtNbr: string
  ChkOrdShipDt: string
  ChkOrdPrvdrNme: string
  ChkOrdPrvdrAddr: ChkOrdPrvdrAddr
  ChkOrdMstrCustId: string
  ChkOrdMastCustNme: string
  ChkOrdInvstgTypeCd: string
  ChkOrdRsltnTypeCd: string
}

export class ChkOrdDlvrAddr {
  StNbrName: string
  AptNbr: string
  CtyStZip: string
}

export class ChkOrdCnsmrNme {
  ChkOrdCnsmrFrstNme: string
  ChkOrdCnsmrMidNme: string
  ChkOrdCnsmrLstNme: string
  ChkOrdCnsmrBusnNme: string
}

export class ChkOrdPrvdrAddr {
  ChkOrdPrvdrAddrLn1: string
  ChkOrdPrvdrCty: string
  ChkOrdPrvdrSt: string
  ChkOrdPrvdrZip: string
  ChkOrdPrvdrPhn: string
}

export class ChkCshEventDtl {
  ChkCshEvent: ChkCshEvent[]
}

export class ChkCshEvent {
  ChkCshDtaMrtKey: string
  ChkCshSsnDiscrpCd: string
  ChkCshPrvdrSrcId: string
  ChkCshCustNme: string
  ChkCshMstrCustId: string
  ChkCshMastCustNme: string
  ChkCshInqDt: string
  ChkCshChkAmt: string
  ChkCshCnsmrNme: ChkCshCnsmrNme
  ChkCshAddr: ChkCshAddr
  ChkCshGovtNbr: string
  ChkCshDlNbr: string
  ChkCshDlStCd: string
  ChkCshInvstgTypeCd: string
  ChkCshRsltnTypeCd: string
}

export class ChkCshCnsmrNme {
  ChkCshCnsmrFrstNme: string
  ChkCshCnsmrMidNme: string
  ChkCshCnsmrLstNme: string
  ChkCshCnsmrBusnNme: string
}

export class ChkCshAddr {
  AptNbr: string
  StNbrName: string
  CtyStZip: string
}

export class NmeVartn {
  VarntNme: string[]
}

export class AddVartnDtl {
  AddVartn: AddVartn[]
}

export class AddVartn {
  VarntAddr: VarntAddr
}

export class VarntAddr {
  VarntAddrLn1: string
  VarntAddrLn2: string
  CtyStZip: string
}

export class PhnVartnDtl {
  PhnVartn: PhnVartn[]
}

export class PhnVartn {
  PhnNmbr: string
  PhnType: string
}

export class EmailAddrVartnDtl {
  EmailVartn: EmailVartn[]
}

export class EmailVartn {
  EmailAddr: string
}
     
}
//////////////////////////////////
