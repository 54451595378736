<div role="region" aria-label="backtodashboard" class="chex-top_label_link"><a href="javascript:void(0);"
    (click)="backToSource()" class=" chex-page-left-right-alignment">{{ 'PDFDOCVIEWER.BACKTODASHBOARD' | translate
    }}</a></div> <br>
<!-- <div role="region" aria-label="info16" class="chex-page-left-right-alignment chex-top-link mat-body-2" [innerHTML]="pdfViewerTitle">
</div> -->
<div aria-label="info16" class="chex-page-left-right-alignment">
  <!-- <ul> -->
    <!-- <li role="region" aria-label="info18" class="points mat-body-2"> -->
      <div><span>{{ 'DOCUMENTVIEWER.DOWNLOAD1' | translate
          }}</span>
          <img src="../assets/toolbarButton-download.png" class="chex-download-icon" alt="">
          <span>{{ 'DOCUMENTVIEWER.DOWNLOAD2' | translate
          }}</span>
          <!-- matTooltip="Download" -->
        <!-- <mat-icon [fisIcon]="fisDownloadIcon"  class="icon fis-icon-sm"></mat-icon> -->
      </div>
    <!-- </li>
  </ul> -->
</div>
<div class="chex-page-left-right-alignment chex-pdf-iframe-container chex-bottom_space">
  <ngx-extended-pdf-viewer *ngIf="base64Data" [base64Src]="base64Data"
  [showToolbar]="true"
  [showSidebarButton]="true"
  [showFindButton]="false"
  [showPagingButtons]="true"
  [showDrawEditor]="false"
  [showStampEditor]="false"
  [showTextEditor]="false"
  [showZoomButtons]="false"
  [showPresentationModeButton]="false"
  [showOpenFileButton]="false"
  [showPrintButton]="false"
  [showDownloadButton]="true"
  [showSecondaryToolbarButton]="true"
  [showRotateButton]="false"
  [showRotateCwButton]="false"
  [showRotateCcwButton]="false"
  [showHandToolButton]="false"
  [showScrollingButtons]="false"
  [showSpreadButton]="false"
  [showPropertiesButton]="false"
  [showInfiniteScrollButton]= "false"
  [showWrappedScrollButton]="false"
  [showBookModeButton]="false"
  >
  <!-- [showScrollWrappedButton]="false"
  [showWrappedScrollingButton]="false" -->
 </ngx-extended-pdf-viewer>

    <!-- Commenting this for unsafe-eval issue in CSP -->
<!-- 
  <ng2-pdfjs-viewer #chexPdfViewer [download]="true" [print]="false" [startDownload]="false" [viewBookmark]="false"
    [openFile]="false" [startPrint]="false" [fullScreen]="false" [find]="false" [lastPage]="false" [rotatecw]="false"
    [rotateccw]="false" [useOnlyCssZoom]="false" [errorOverride]="false" [errorAppend]="false" [diagnosticLogs]="false">
  </ng2-pdfjs-viewer> -->

</div>
