import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgModel,
} from '@angular/forms';

import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { SecurityAlertService } from '../_services/security-alert/security-alert.service';
import { userinformation } from '../_models/classes/user-information.model';
import { securityalert } from '../_models/classes/security-alert.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../dailog/dialog-with-message/dialog-with-message.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogwithmessagebtnComponent } from '../dailog/dialog-with-message-btn/dialog-with-message-btn.component';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SitecoreService } from '../_services/sitecore/sitecore.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormValidation } from '../common/validation-common/form-validator';
import { GlobalVariables } from '../common/enums/global_variables';
import { GlobalErrorHandler } from '../_services/shared/global-error-handler.service';


@Component({
  selector: 'chx-place-security-alert',
  templateUrl: './place-security-alert.component.html',
  styleUrls: ['./place-security-alert.component.scss'],
})
export class PlaceSecurityAlertComponent {
  selectedQuestion: string = '';
  invalidQuestion: boolean = false;
  formGroup: FormGroup;
  termsnconditions: boolean = false;
  selectedQuestionCode: string = '01';
  items: AlertQuestions[] = [];
  alert_title: string;
  placeSecurityAlertContent: string = '';
  placeSecurityAlertPath: string = '';
  invalidCharErrorMessage: string;
  title=this._translate.instant('PLACESECURITYALERT.TITLE');
  quesans=this._translate.instant('PLACESECURITYALERT.QUESANS');
  info12=this._translate.instant('PLACESECURITYALERT.INFO12');
  info11=this._translate.instant('PLACESECURITYALERT.INFO11');
  submit=this._translate.instant('BUTTONS.SUBMIT');

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private piConfirmService: PersonalInformationConfirmationService,
    private securityAlertService: SecurityAlertService,
    private httpClient : HttpClient,
    private dialog: MatDialog,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService,
    private _globalErrorHandler:GlobalErrorHandler
  ) { 
    this.invalidCharErrorMessage  = this._translate.instant(
      'POPUPS.PLACEALERTINVALIDCHARERROR'
    );
    this.formGroup = this._formBuilder.group({
      txtAnswer: ['', Validators.required],
      questionCtrl: ['', Validators.required],
    },
      {
        validators: [
          FormValidation.SpecialCharacterValidationForSecurityAlert('txtAnswer')
        ],
      });
      this.getSecurityAlertQuestions().subscribe((res: AlertQuestions[]) => {
        this.items = res;
      });
    }
  
  
  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('PlaceaSecurityAlert');
    this.fetchPlaceSecurityAlertContent();
  }

  fetchPlaceSecurityAlertContent() {
    let manageSecurityFreezeContentPath: string = 'Place Security Alert/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: manageSecurityFreezeContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'place security alert') {

          const divplaceSecurityAlert = document.createElement('div');
          divplaceSecurityAlert.innerHTML = fieldObject.value.toString();
          var anchorelements = divplaceSecurityAlert.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            anchorelement.href ="assets/pdf/Affidavit.pdf";
            // anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
          });

          this.placeSecurityAlertContent = divplaceSecurityAlert.innerHTML;
        } 
        if (child.name.toLowerCase() == 'identity theft pdf path') {

          const divplaceSecurityAlert = document.createElement('div');
          divplaceSecurityAlert.innerHTML = fieldObject.value.toString();
          var anchorelements = divplaceSecurityAlert.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            // anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
             anchorelement.href ="assets/pdf/Affidavit.pdf";
          });
          this.placeSecurityAlertPath = divplaceSecurityAlert.innerHTML ;
        }
      });

    }, 
    error => {
    }, 
    ()=>{
    }
    );
  }

  submitclick() {
    if (this.formGroup.valid) {
      let answer = this.formGroup.controls['txtAnswer'].value;

      const strUserInfo = GlobalVariables.globalvars.get('UserInformation');
      if (strUserInfo) {
        const objUserInfo = JSON.parse(
          strUserInfo
        ) as userinformation.UserInformationRoot;
        const alertReq: securityalert.SecurityAlertRequest =
          new securityalert.SecurityAlertRequest();
        const alertReqRoot: securityalert.SecurityAlertRequestRoot =
          new securityalert.SecurityAlertRequestRoot();
        alertReq.cellPhoneNbr = objUserInfo.CellPhoneNbr.trim();
        alertReq.cnsmrAddrDetls = new securityalert.CnsmrAddrDetls();
        alertReq.cnsmrAddrDetls.cnsmrAddr = new securityalert.CnsmrAddr();
        alertReq.cnsmrAddrDetls.cnsmrAddr.addrLine1 =
          objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine1.trim();
        alertReq.cnsmrAddrDetls.cnsmrAddr.addrLine2 =
          objUserInfo.CnsmrAddrDetls.CnsmrAddr.AddrLine2.trim();
        alertReq.cnsmrAddrDetls.cnsmrAddr.cityNm =
          objUserInfo.CnsmrAddrDetls.CnsmrAddr.CityNm.trim();
        alertReq.cnsmrAddrDetls.cnsmrAddr.statCode =
          objUserInfo.CnsmrAddrDetls.CnsmrAddr.StatCode.trim();
        alertReq.cnsmrAddrDetls.cnsmrAddr.zipCode =
          objUserInfo.CnsmrAddrDetls.CnsmrAddr.ZipCode.trim();
        alertReq.cnsmrPrsnlDetls = new securityalert.CnsmrPrsnlDetls();
        alertReq.cnsmrPrsnlDetls.cnsmrNmDetls =
          new securityalert.CnsmrNmDetls();
        alertReq.cnsmrPrsnlDetls.cnsmrNmDetls.frstNm =
          objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim();
        alertReq.cnsmrPrsnlDetls.cnsmrNmDetls.lastNm =
          objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim();
        alertReq.cnsmrPrsnlDetls.cnsmrNmDetls.midNm =
          objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim();
        alertReq.cnsmrPrsnlDetls.cnsmrNmDetls.suffixNm =
          objUserInfo.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim();
        alertReq.cnsmrPrsnlDetls.birthDt = objUserInfo.CnsmrPrsnlDetls.BirthDt;
        alertReq.cnsmrPrsnlDetls.govtNbr =
          objUserInfo.CnsmrPrsnlDetls.GovtNbr.trim();
        alertReq.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls =
          new securityalert.CnsmrDrivLicenseDetls();
        alertReq.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseIssuSt =
          objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim();
        alertReq.cnsmrPrsnlDetls.cnsmrDrivLicenseDetls.drivLicenseNbr =
          objUserInfo.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim();
        alertReq.cnsmrPrsnlDetls.phoneNbr =
          objUserInfo.CnsmrPrsnlDetls.PhoneNbr.trim();
        alertReq.correspondenceDeliveryPreference =
          objUserInfo.CorrespondenceDeliveryPreference.trim();
        alertReq.notificationEmailAddr =
          objUserInfo.NotificationEmailAddr.trim();
        alertReq.recvdDtTm = this.ConvertDateTime(new Date()); // DD/MM/YYYhh:mm:ss
        alertReq.requestSrcCode = 'SECURE';
        alertReq.userName = objUserInfo.UserName;
        alertReq.rqstHdr = new securityalert.RqstHdr();
        alertReq.rqstHdr.rqstSubTyp = 'CAS';
        alertReq.rqstHdr.rqstTyp = 'SA';
        const reqQues: securityalert.QuesAndAnsDetls =
          new securityalert.QuesAndAnsDetls();
        reqQues.ansText = answer;
        reqQues.questionCode = this.selectedQuestionCode;
        alertReq.quesAndAnsDetls = reqQues;
        alertReqRoot.securityAlertRequest = alertReq;
        this.securityAlertService.AddSecurityAlert(alertReqRoot).subscribe(
          (res) => {
            // const strMessage = res.message;
            let strMessage = res.message;
            if (res.code === '4061') {
              this.alert_title = this._translate.instant(
                'POPUPS.PLACESECURITYALERTTITLE1'
              );
              strMessage = this._translate.instant('POPUPS.PLACEALERTEXISTSTEXT');
              
            } else {
              this.alert_title = this._translate.instant(
                'POPUPS.PLACESECURITYALERTTITLE2'
              );
              strMessage =
                this._translate.instant('POPUPS.PLACESECURITYALERTTEXT') + this.placeSecurityAlertPath + this._translate.instant('POPUPS.PLACESECURITYALERTTEXT1');
              strMessage = strMessage.replace('#######', res.referenceId);
            }
            const dialogMessage = new DialogMessage(
              strMessage,
              this._translate.instant('BUTTONS.GOTODASHBOARD'),
              false,
              this.alert_title
            );
            const dialogRef2 = this.dialog.open(DialogwithmessagebtnComponent, {
              panelClass: ['fis-style', 'custom-dialog-without-close'],
              data: dialogMessage,
              disableClose: true,
            });
            dialogRef2.afterClosed().subscribe((result) => {
              if ((result as string) == 'OK') {
                this.router.navigate(['dashboard']);
                //window.location.reload();
              }
            });
          },
          (error) => {
            //this._globalErrorHandler.handleErrorEx(error, "place-security-alert->submitclick");
           },
          () => { }
        );
      }
    }
    else{
      this.selectedQuestion.trim()=='' ? this.invalidQuestion = true : this.invalidQuestion = false;
    }
  }

  navigateToTermsandConditions(event: any) {}

  private ConvertDateTime(dateValue: Date): string {
    return (
      this.ConvertValidDateFormat(dateValue) +
      '' +
      dateValue.getHours() +
      ':' +
      dateValue.getMinutes() +
      ':' +
      dateValue.getSeconds()
    );
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = mm + '/' + dd + '/' + yyyy;
    return validDate;
  }

  getSecurityAlertQuestions() {
    return this.httpClient.get<AlertQuestions[]>(
      '../../../assets/jsondata//security-alert-questions.json'
    );
  }

  radioChange(objQuestion: AlertQuestions) {
    this.invalidQuestion = false;
    this.selectedQuestionCode = objQuestion.questionCode;
  }
}

export class AlertQuestions {
  question: string;
  questionCode: string;
  checked: boolean;
}
