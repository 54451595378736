import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { FieldName } from '../../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SiteCoreNodePathResponseData, State, States } from '../../_models/classes/sitecore-nodepath-reponse.model';
import { Field, SitecoreRequestData, SiteCoreResponseData } from '../../_models/classes/sitecore-reponse.model';
import { Registrationresource } from '../../_models/registration/registrationresource.model';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import { SitecoreService } from '../../_services/sitecore/sitecore.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'chexsystem-ws-security-freeze-notice',
  templateUrl: './security-freeze-notice.component.html',
  styleUrls: ['./security-freeze-notice.component.scss']
})
export class SecurityFreezeNoticeComponent implements OnInit {
  isLoader: boolean = false;
  contentText1: any;
  topText: string = '';
  statusBarText: string = '';
  states: State[] = [];
  columns: number[] = [];
  columnCount: number;
  title=this._translate.instant('SECURITYFREEZENOTICE.TITLE');
  info6=this._translate.instant('SECURITYFREEZENOTICE.INFO6');
  statesLink:any;
  constructor(private http: HttpClient, private router: Router, private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService
    ) { }

  direction() {
    this.router.navigate(['']);
  }

  ngOnInit(): void {
    this.piConfirmService.checkPersonalInformationConfirmation('NoticeofRights');
    this.getStatesLink().subscribe((res) => {
      this.statesLink = res as State;
    });
    this.fetchNoticeOfRightsContent();
  }

  getStatesLink() {
    return this.http.get('./././assets/jsondata/sateLink.json');
  }

  fetchNoticeOfRightsContent() {
    let carouselContentPath: string = 'Notice of Rights/Data/Content/Data';
    this.isLoader = true;
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: carouselContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;

      result.data.item.children?.forEach((child) => {

        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());
        if (child.name.toLowerCase() == 'state data') {
          const divnoticeofrights = document.createElement('div');
          divnoticeofrights.innerHTML = fieldObject.value.toString();
          var anchorelements = divnoticeofrights.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            let state: State = new State();
            anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
            state.name = anchorelement.text;
            state.link = anchorelement.href;
            if (state.name != '' && state.link != '')
              this.states.push(state);
          });
          for (var i = 0; i < Math.ceil(this.states.length / 10); i++)
            this.columns.push(i);
        }
        else if (child.name.toLowerCase() == 'notice of rights') {
          const divnoticeofrights1 = document.createElement('div');
          divnoticeofrights1.innerHTML = fieldObject.value.toString();
          var anchorelements = divnoticeofrights1.querySelectorAll("a");
          anchorelements.forEach(anchorelement => {
            if(!Registrationresource.PagesToBeRouted.some(pageToBeRouted => anchorelement.href.indexOf(pageToBeRouted) >= 0)){
            anchorelement.href = anchorelement.href.replace(anchorelement.baseURI, environment.sitecorebaseurl);
          }
          });

          this.topText = divnoticeofrights1.innerHTML;
        }
        else if (child.name.toLowerCase() == 'status bar') {
          this.statusBarText = fieldObject.value;
        }
      });
      this.isLoader = false;

    }, 
    error => {
      this.isLoader = false;
    }, 
    ()=>{
      this.isLoader = false;
    }
    );
  }

}
