import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FieldName } from '../_models/classes/sitecore-dashboard-carousel-reponse.model copy';
import { SiteCoreNodePathResponseData } from '../_models/classes/sitecore-nodepath-reponse.model';
import { Field, SitecoreRequestData } from '../_models/classes/sitecore-reponse.model';
import { PersonalInformationConfirmationService } from '../_services/shared/pi-confirmation.service';
import { SitecoreService } from '../_services/sitecore/sitecore.service';

@Component({
  selector: 'chexsystem-ws-identify-theft',
  templateUrl: './identify-theft.component.html',
  styleUrls: ['./identify-theft.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IdentifyTheftComponent implements OnInit {
  identifyTheftContent1: string = '';
  identifyTheftContent2: string = '';
  termsnconditions = "";
  submit=this._translate.instant('BUTTONS.SUBMIT');

  constructor(private router: Router,
    private _formBuilder: FormBuilder,
    private piConfirmService: PersonalInformationConfirmationService,
    private sitecoreService: SitecoreService,
    private _translate: TranslateService) { }

  ngOnInit(): void {
    // this.piConfirmService.checkPersonalInformationConfirmation();
    this.fetchHumanTraffickingContent();
  }

  submitclick(){
    this.router.navigate( ['id-theft-block']);
  }

  fetchHumanTraffickingContent() {
let identityTheftContentPath: string = 'Identity Theft Block/Data/Content/Data';
    let siteCoreRequestData: SitecoreRequestData = <SitecoreRequestData>{ itempath: identityTheftContentPath };
    this.sitecoreService.FetchSitecoreContentByNodePath(siteCoreRequestData).subscribe((res) => {
      let result = res as SiteCoreNodePathResponseData;
      result.data.item.children?.forEach((child) => {
        let fieldObject = <Field>child.fields?.find(field => field.name == FieldName.Text.toString());        
        if (child.name.toLowerCase() == 'identity-theft-1') {
          this.identifyTheftContent1 = fieldObject.value;
        }
        if (child.name.toLowerCase() == 'identity-theft-2') {
          this.identifyTheftContent2 = fieldObject.value;
        }
      });

    });
  }
}
