import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PersonalInformationConfirmationService } from '../../_services/shared/pi-confirmation.service';
import {
  CnsmrAddr,
  UserLookupRequest,
} from '../../_models/registration/UserDetail';
import { IdaQuizService } from '../../_services/registration/ida-quiz.service';
import { UserdisclosureService } from '../../_services/disclosure/user-disclosure.service';
import {
  ConsumerDisclosureRequest,
  DisclosureRequestheader,
} from '../../_models/consumer-disclosure/consumer-disclosure.model';
import {
  DialogMessage,
  DialogwithmessageComponent,
} from '../../dailog/dialog-with-message/dialog-with-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { DashboardCorrespondenceItems } from '../dashboard-common/dashboard-correspondence/dashboard-correspondence.component';
import { dashboardCorrs } from '../../_models/classes/dashboard-correspondence.model';
import { DashboardService } from '../../_services/dashboard-services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { userinformation } from '../../_models/classes/user-information.model';
import { GlobalVariables } from '../enums/global_variables';
import { MailDisclosureReportRequest } from '../../_models/classes/mail-disclosure-report-request';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { timeout, TimeoutError } from 'rxjs';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';


@Component({
  selector: 'chx-pdf-doc-viewer',
  templateUrl: './pdf-doc-viewer.component.html',
  styleUrls: ['./pdf-doc-viewer.component.scss'],
})
export class PdfViewerComponent {
  confirmationNo: string;
  backSource: string;
  repJson: string;
  fisDownloadIcon: string;
  dataLocalUrl: any;
  backtodashboard = this._translate.instant('PDFDOCVIEWER.BACKTODASHBOARD');
  info16 = this._translate.instant('DOCUMENTVIEWER.TITLE1');
  info18 = this._translate.instant('DOCUMENTVIEWER.DOWNLOAD');
  pdfViewerTitle = this._translate.instant('DOCUMENTVIEWER.TITLE');
  base64Data: any;
  @ViewChild('chexPdfViewer') pdfViewerAutoLoad!: PdfJsViewerComponent;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private activeRouter: ActivatedRoute,
    private http: HttpClient,
    private piConfirmService: PersonalInformationConfirmationService,
    private decisionSolutionService: IdaQuizService,
    private disclosureService: UserdisclosureService,
    public dialogWithMessage: MatDialog,
    private urlRoutingPath: Location,
    private dashboardService: DashboardService,
    private _translate: TranslateService,
    // private userDisclosureService: UserdisclosureService
  ) {
    this.activeRouter.params.subscribe((params) =>
      this.setParam(params['src'], params['id'])
    );
  }

  setParam(srcP: string, cNo: string) {
    this.backSource = srcP;
    this.confirmationNo = cNo;
  }

  backToSource() {
    this.router.navigate(['dashboard']);
  }

  ngOnInit() {
    this.piConfirmService.checkPersonalInformationConfirmation();
    if (this.backSource === 'disclosure') {
      this.getConsumerDisclosure();
    } else if (this.backSource === 'dashboard') {
      this.getDashboardCorres();
    }
    // change the url
    this.fisDownloadIcon = "download";
  }

  getConsumerDisclosure() {
    
    this.urlRoutingPath.replaceState('/disclosure-report');
    let lookupRequest: UserLookupRequest = new UserLookupRequest();
    var _userName = GlobalVariables.globalvars.get('UserId') || '';

    lookupRequest.UserLookupReq = { UserName: _userName, Cid: '' };
    let previousAddressDetail: CnsmrAddr = {
      AddrLine1: '',
      AddrLine2: '',
      CityNm: '',
      StatCode: '',
      ZipCode: '',
    };

    const userProfileInfo = GlobalVariables.globalvars.get('UserInformation');
    if (userProfileInfo) {
      const ConsumerReferenceId: string = Math.floor(Math.random() * 1000000000).toString();
      const userProfile = JSON.parse(userProfileInfo) as userinformation.UserInformationRoot;
      let cstDateTimeNow: Date = new Date(
        new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
      );
      const cstDateTimeNowValue: string = this.ConvertDateTime(cstDateTimeNow);
      let consumerDisclosureRequest: ConsumerDisclosureRequest | undefined =
      {
        DisclosureReq: {
          CnsmrAddrDetls: userProfile.CnsmrAddrDetls,
          CnsmrPrsnlDetls: {
            BirthDt: userProfile.CnsmrPrsnlDetls.BirthDt,
            CnsmrDrivLicenseDetls: {
              DrivLicenseIssuSt: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim(),
              DrivLicenseNbr: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim(),
            },
            CnsmrNmDetls: {
              FrstNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
              LastNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
              MidNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim(),
              PreviousLastName: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName.trim(),
              SuffixNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim(),
            },
            ConsumerId: '',
            EmailAddress: userProfile.NotificationEmailAddr.trim(),
            GovtNbr: userProfile.CnsmrPrsnlDetls.GovtNbr.trim(),
            PhoneNbr: userProfile.CnsmrPrsnlDetls.PhoneNbr,
          },
          CnsmrRefId: ConsumerReferenceId,
          CommentTxt: DisclosureRequestheader.CommentTxt,
          PreviousAddrDetls: {
            PrevAddr1: previousAddressDetail,
            PrevAddr2: previousAddressDetail,
            PrevAddr3: previousAddressDetail,
          },
          RecvdDtTm: cstDateTimeNowValue,
          RequestSrcCode: DisclosureRequestheader.RequestSrcCode,
          RequestTypCode: DisclosureRequestheader.RequestTypCode,
          UserName : userProfile.UserName,
          CellPhoneNbr : userProfile.CellPhoneNbr,
          CorrespondenceDeliveryPreference : userProfile.CorrespondenceDeliveryPreference,
          NotificationEmailAddr : userProfile.NotificationEmailAddr,
          RqstHdr: {
            RqstSubTyp: DisclosureRequestheader.RqstSubTyp,
            RqstTyp: DisclosureRequestheader.RqstTyp,
          },
        },
      };
      
      this.disclosureService
        .getConsumerDisclosureReport(consumerDisclosureRequest)
        .pipe(timeout(56000))
        .subscribe(
          (reportResponse) => {
            if (
              reportResponse.DisclosureRsp.rspCd === '200' &&
              reportResponse.DisclosureRsp.rspMsg.toLowerCase() === 'passed'
            ) {
              let base64EnCodedData =
                reportResponse.DisclosureRsp.discRspEncData;
                this.base64Data = base64EnCodedData;
              
                //Commenting below for unsafe-eval issue in csp for ng2-pdfjs-viewer
                // pdf viewer
                // let pdfSource = this.base64toBlob(base64EnCodedData);
                // this.pdfViewerAutoLoad.pdfSrc = pdfSource;
                // this.pdfViewerAutoLoad?.refresh(); 
            } else if (reportResponse.DisclosureRsp.rspCd != undefined && reportResponse.DisclosureRsp.rspCd != null && reportResponse.DisclosureRsp.rspCd === "4105") {
              this.failedToGenerateReportDailyLimit(this._translate.instant('MAILDISCLOSURE.DAILYLIMITS'));
            } else {
              this.generateReport(userProfile);
              // call Mail disclosure if online failed
              this.mailDisclosureReportCall(userProfile);
            }
          },
          (error) => {
            if(error instanceof  TimeoutError) {
              console.log("Timeout error", error);
              this.generateReport(userProfile);
              this.mailDisclosureReportCall(userProfile);
            }
           
          },
          () => { }
        );
    }
  }

  generateReport(userProfile: any) {
    if (userProfile.CorrespondenceDeliveryPreference === 'Mail') {
      this.failedToGenerateReport(this._translate.instant('POPUPS.DISCLOSURESERVICEEMAILERRORMESSAGE'));
    } else if (userProfile.CorrespondenceDeliveryPreference === 'Portal') {
      this.failedToGenerateReport(this._translate.instant('POPUPS.DISCLOSURESERVICEPORTALERRORMESSAGE'));
    }
  }
  getDashboardCorres() {
    // change the url
    let strObj: string =
      GlobalVariables.globalvars.get('DashboardCorrespondenceItems') || '';
    if (strObj != '') {
      let _userName = GlobalVariables.globalvars.get('UserId') || '';
      let reqData: DashboardCorrespondenceItems = JSON.parse(
        strObj
      ) as DashboardCorrespondenceItems;
      let dcReqRoot: dashboardCorrs.CorrespondenceListDetailRequestRoot =
        new dashboardCorrs.CorrespondenceListDetailRequestRoot();
      dcReqRoot.CorrespondenceListDetailRequest =
        new dashboardCorrs.CorrespondenceListDetailRequest();
      dcReqRoot.CorrespondenceListDetailRequest.ConsumerReferenceId =
        reqData.referenceNumber;
      dcReqRoot.CorrespondenceListDetailRequest.DocumentOid =
        reqData.DocumentOid;
      dcReqRoot.CorrespondenceListDetailRequest.ProcessOid = reqData.ProcessOid;
      dcReqRoot.CorrespondenceListDetailRequest.UserName = _userName;
      this.dashboardService.CorrespondenceListDetail(dcReqRoot).subscribe(
        (res) => {
          let base64EnCodedData =
            res.CorrespondenceListDetailResponse.LetterDetailsEncData;
            this.base64Data = base64EnCodedData;
        
          //Commenting below for unsafe-eval issue in csp for ng2-pdfjs-viewer
          // pdf viewer
          // let pdfSource = this.base64toBlob(base64EnCodedData);
          // this.pdfViewerAutoLoad.pdfSrc = pdfSource;
          // this.pdfViewerAutoLoad?.refresh(); 
        },
        (error) => { },
        () => { }
      );
    }
  }

  private failedToGenerateReport(messageContnet: string) {
    const dialogMessage = new DialogMessage(
      messageContnet,
      this._translate.instant('BUTTONS.GOTODASHBOARD'), //this.disclosureServiceErrorActionButton,
      false,
      this._translate.instant('POPUPS.ONLINEDELIVERYUNAVAILABLETITLE') //this.disclosureServiceErrorTitle
    );
    const dialogRef2 = this.dialogWithMessage.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) === 'OK') {
        this.router.navigate(['dashboard']);
      }
    });
  }

  private failedToGenerateReportDailyLimit(messageContnet: string) {
    const dialogMessage = new DialogMessage(
      messageContnet,
      this._translate.instant('BUTTONS.GOTODASHBOARD'), //this.disclosureServiceErrorActionButton,
      false,
      this._translate.instant('MAILDISCLOSURE.TITLE1') //this.disclosureServiceErrorTitle
    );
    const dialogRef2 = this.dialogWithMessage.open(DialogwithmessageComponent, {
      panelClass: ['fis-style', 'custom-dialog-without-close'],
      data: dialogMessage,
      disableClose: true,
    });
    dialogRef2.afterClosed().subscribe((result) => {
      if ((result as string) === 'OK') {
        this.router.navigate(['dashboard']);
      }
    });
  }
  
  private ConvertDateTime(dateValue: Date): string {
    return (
      this.ConvertValidDateFormat(dateValue) +
      'T' +
      this.padLeadingZeros(dateValue.getHours(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getMinutes(), 2) +
      ':' +
      this.padLeadingZeros(dateValue.getSeconds(), 2)
    );
  }

  private ConvertValidDateFormat(dateValue: Date): string {
    var dd = String(dateValue.getDate()).padStart(2, '0');
    var mm = String(dateValue.getMonth() + 1).padStart(2, '0');
    var yyyy = dateValue.getFullYear();
    let validDate = yyyy + '-' + mm + '-' + dd;
    return validDate;
  }
  private ConvertPhoneFormate(phoneNumber: string): string {
    if (phoneNumber === undefined || phoneNumber === '' || phoneNumber === ' ')
      return '';
    phoneNumber = phoneNumber
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '$1,$2,$3');
    let formattedPhoneNumber = phoneNumber.split(',', 3);
    return (
      formattedPhoneNumber[0] +
      formattedPhoneNumber[1] +
      formattedPhoneNumber[2]
    );
  }

  private padLeadingZeros(num: number, size: number) {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }

  private mailDisclosureReportCall(userProfile: userinformation.UserInformationRoot) {
    const ConsumerReferenceId: string = Math.floor(Math.random() * 1000000000).toString();
    let cstDateTimeNow: Date = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
    );
    const cstDateTimeNowValue: string = this.ConvertDateTime(cstDateTimeNow);
    let previousAddress = {
      addrLine1: "",
      addrLine2: "",
      cityNm: "",
      statCode: "",
      zipCode: ""
    }
    let mailDisclosureRequest: MailDisclosureReportRequest = {
      disclosureReq: {
        cnsmrAddrDetls: {
          cnsmrAddr: {
            addrLine1: userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine1,
            addrLine2: userProfile.CnsmrAddrDetls.CnsmrAddr.AddrLine2,
            cityNm: userProfile.CnsmrAddrDetls.CnsmrAddr.CityNm,
            statCode: userProfile.CnsmrAddrDetls.CnsmrAddr.StatCode,
            zipCode: userProfile.CnsmrAddrDetls.CnsmrAddr.ZipCode
          }
        },
        cnsmrPrsnlDetls: {
          birthDt: userProfile.CnsmrPrsnlDetls.BirthDt,
          cnsmrDrivLicenseDetls: {
            drivLicenseIssuSt: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseIssuSt.trim(),
            drivLicenseNbr: userProfile.CnsmrPrsnlDetls.CnsmrDrivLicenseDetls.DrivLicenseNbr.trim()
          },
          cnsmrNmDetls: {
            frstNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.FrstNm.trim(),
            lastNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.LastNm.trim(),
            midNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.MidNm.trim(),
            previousLastName: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.PreviousLastName,
            suffixNm: userProfile.CnsmrPrsnlDetls.CnsmrNmDetls.SuffixNm.trim(),
          },
          consumerId: ConsumerReferenceId,
          email: userProfile.NotificationEmailAddr.trim(),
          govtNbr: userProfile.CnsmrPrsnlDetls.GovtNbr.trim(),
          phoneNbr: userProfile.CnsmrPrsnlDetls.PhoneNbr.trim()
        },
        previousAddrDetls: {
          prevAddr1: previousAddress,
          prevAddr2: previousAddress,
          prevAddr3: previousAddress
        },
        recvdDtTm: cstDateTimeNowValue,
        requestSrcCode: DisclosureRequestheader.RequestSrcCode,
        requestTypCode: DisclosureRequestheader.MailType,
        userName: userProfile.UserName,
        cellPhoneNbr: userProfile.CellPhoneNbr,
        correspondenceDeliveryPreference: userProfile.CorrespondenceDeliveryPreference,
        notificationEmailAddr: userProfile.NotificationEmailAddr,
        rqstHdr: {
          rqstSubTyp: DisclosureRequestheader.MailSybType,
          rqstTyp: DisclosureRequestheader.MailType
        }
      }
    };
    // one way call, no need to handle response
    this.disclosureService.mailDisclosureRequest(mailDisclosureRequest).subscribe(
      (response) => {},
      (error) => { },
      () => { });
  }

  detectBrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    const browser =
      agent.indexOf('edge') > -1 ? 'Microsoft Edge'
        : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
          : agent.indexOf('opr') > -1 ? 'Opera'
            : agent.indexOf('chrome') > -1 ? 'Chrome'
              : agent.indexOf('trident') > -1 ? 'Internet Explorer'
                : agent.indexOf('firefox') > -1 ? 'Firefox'
                  : agent.indexOf('safari') > -1 ? 'Safari'
                    : 'other';

    return browser;
  }

  base64toBlob(base64Data: any): Blob {
    const contentType = 'application/pdf';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}
export class PDFApiData {
  fileName: string;
  type: string;
  FileBytes: string;
}
